import * as React from "react";
import { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";

export default function PositionedSnackbar(props) {
  const { handlePopUp, message, handleClosePopup } = props;
  const [state, setState] = useState(false);
  const vertical = "top";
  const horizontal = "right";

  const handleClick = () => {
    setState(true);
  };

  const handleClose = () => {
    setState(false);
  };

  useEffect(() => {
    if (handlePopUp) {
      handleClick();
      setTimeout(() => {
        handleClose();
        handleClosePopup();
      }, 3000);
    } else {
      handleClosePopup();
    }
    return () => {
      handleClose();
      handleClosePopup()
    };
  }, [handlePopUp]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={state}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
      />
    </div>
  );
}
