import React, { useState, useEffect } from "react";
import "../Signin.scss";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate as useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import Header from "../Shared/Header";
import TextField from "@mui/material/TextField";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import { useLocation } from "react-router-dom";
import Dailog from "../Shared/Dailog";
import Popup from "../Shared/PopUp";
import CONSTANTS from "../../constants";
import { getQueryString } from "../Shared/data";
import TimePicker from "rc-time-picker";
import DatePicker from "react-datepicker";
import moment from "moment";
// var moment = require("moment-timezone");
const heading = "EDIT BROADCAST";
let startTime;
let defaultDate;
let endTime;
let now;
const format = "hh:mm A";

const EditBroadcast = (props) => {
  const history = useHistory();
  const location = useLocation();
  let eventDate = location?.state?.broadcastDetail?.date;
  defaultDate = moment(eventDate).format("YYYY-MM-DD");
  startTime = location?.state?.broadcastDetail.startTime;
  endTime = location?.state?.broadcastDetail.endTime;
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId");
  const { classes } = props;
  const dateFormat = (value) => {
    const payload = value.split(' ');
    const timeSplit = payload[0].split(':');
    return payload[1] === 'PM' ? `${Number(timeSplit[0]) + 12}:${timeSplit[1]}` : payload[0];
  };
  const [shedulerValues, setShedulerValues] = useState({
    broadcastName: location?.state?.broadcastDetail?.eventName || "",
    broadcastDate: defaultDate,
    broadcastStartTime: moment(new Date(`${defaultDate} ${dateFormat(startTime)}`)).isValid() 
      ? moment(new Date(`${defaultDate} ${dateFormat(startTime)}`)).format("HH:mm") 
      : "",
    broadcastEndTime: moment(new Date(`${defaultDate} ${dateFormat(endTime)}`)).isValid() 
      ? moment(new Date(`${defaultDate} ${dateFormat(endTime)}`)).format("HH:mm") 
      : "",
  });
  const [popUpMessage, setPopUpMessage] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorDailog, setErrorDailog] = useState("");
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [dispatchTime, setDispatchTime] = useState(moment());
  const [dispatchEndTime, setDispatchEndTime] = useState(moment());
  const [eventLiveStatus, setEventLiveStatus] = useState(false);

  useEffect(() => {
    if (location?.state?.broadcastDetail) {
      setDispatchTime(
        moment(
          location?.state?.broadcastDetail?.date +
          " " +
          location?.state?.broadcastDetail?.startTime
        )
      );
      setDispatchEndTime(
        moment(
          location?.state?.broadcastDetail?.date +
          " " +
          location?.state?.broadcastDetail?.endTime
        )
      );
      setStartDate(
        new Date(
          location?.state?.broadcastDetail?.date +
          " " +
          location?.state?.broadcastDetail?.startTime
        )
      );
      liveStatus();
    }
  }, [location?.state?.broadcastDetail]);

  const liveStatus = async () => {
    try {
      const data = {
        venueId: venueId,
      };
      const request = await axios.get(
        requests.venueBroadccastDetail + "?" + getQueryString(data)
      );
      if (
        request?.data?.data?.eventId &&
        location?.state?.broadcastDetail?.eventId ==
        request?.data?.data?.eventId
      ) {
        setEventLiveStatus(request?.data?.data?.live);
      }
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      } else {
        setHandlePopUp(true);
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
      console.log(error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setShedulerValues((preValue) => {
      return { ...preValue, [name]: value };
    });
  };
  const onEndChange = (value) => {
    if (value) {
      const formattedTime = moment(value).format("hh:mm A");
      // alert(`You selected End Time: ${formattedTime}`);
  
      setDispatchEndTime(value);
      setShedulerValues((prev) => ({
        ...prev,
        broadcastEndTime: moment(value).format("HH:mm"),
      }));
    }
  };
  const handleValueChange = (value) => {
    if (value) {
      const formattedTime = moment(value).format("hh:mm A");
      // alert(`You selected Start Time: ${formattedTime}`);
  
      setDispatchTime(value);
      setShedulerValues((prev) => ({
        ...prev,
        broadcastStartTime: moment(value).format("HH:mm"),
      }));
    }
  };
  
  const handleDateChange = (value) => {
    const formattedDate = moment(value).format("YYYY-MM-DD");
    // alert(`You selected: ${formattedDate}`);
  
    setShedulerValues((prev) => ({
      ...prev,
      broadcastDate: formattedDate,
    }));
  
    setStartDate(value);
  };
  

  const handleCloseSuccess = () => {
    setSuccessMessage("");
    history(`/broadcasts?venueId=${venueId}`, {
      state: { detail: location?.state?.detail },
    });
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };

  const handleCancel = () => {
    setErrorDailog("");
  };
  const handleConfig = () => {
    history("/broadcastChannel");
  };

  const menuList = [
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (shedulerValues.broadcastName) {
      try {
        let eventEnd = `${shedulerValues.broadcastDate}T${shedulerValues.broadcastEndTime}`;
        let eventStart = `${shedulerValues.broadcastDate}T${shedulerValues.broadcastStartTime}`;
        const data = {
          eventName: shedulerValues.broadcastName,
          endDateTime: eventEnd,
          startDateTime: eventStart,
          venueId: venueId,
        };
        const request = await axios.put(
          requests.resheduleEvent +
          `/${location?.state?.broadcastDetail?.eventId}`,
          data
        );
        setSuccessMessage(CONSTANTS.VARIABLES.EVENT_EDITED_SUCCESS);
      } catch (error) {
        if (error.response.data.message) {
          setErrorDailog(error.response.data.message);
        } else if (error.response.status === 403) {
          handleLogout();
        } else {
          setErrorDailog(CONSTANTS.VARIABLES.NETWORK_ERROR);
        }
      }
    } else {
      setHandlePopUp(true);
      setPopUpMessage(CONSTANTS.VARIABLES.BROADCAST_NAME);
    }
  };
  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              {successMessage ? (
                <Dailog
                  handleCancel={handleCloseSuccess}
                  errorDailog={successMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                style={classes.boxWidth}
                noValidate
                onSubmit={handleSubmit}
                fullWidth
                sx={{ mt: 3 }}
              >
                {errorDailog ? (
                  <Dailog
                    handleCancel={handleCancel}
                    errorDailog={errorDailog}
                  />
                ) : (
                  " "
                )}
                <div className="customer-detail" style={classes.boxWidth}>
                  <Typography className="detail">
                    {venueId}
                  </Typography>
                  <Typography className="detail">
                    {location?.state?.detail?.venueName}
                  </Typography>
                  <Typography className="detail">
                    {location?.state?.detail?.timeZone}
                  </Typography>
                </div>
                <TextField
                  margin="normal"
                  required
                  id="broadcastName"
                  label="Broadcast Name Input"
                  name="broadcastName"
                  autoFocus
                  fullWidth
                  value={shedulerValues.broadcastName}
                  style={classes.inputFieldBold}
                  size="small"
                  color="error"
                  onChange={handleChange}
                />
                <div className="time-date-box">
                  <label htmlFor="broadcastStartTime">Broadcast Date</label>
                  <input type="text" id="broadcastStartTime" />
                </div>
                <DatePicker
                  className="datePicker"
                  dateFormat="MMM-dd-yyyy"
                  selected={startDate}
                  onChange={(value) => handleDateChange(value)}
                />
                <div className="time-box">
                  <TimePicker
                    value={dispatchTime}
                    onChange={handleValueChange}
                    showSecond={false}
                    allowEmpty
                    label="Broadcast Start Time"
                    placeholder="Broadcast Start Time"
                    className="timeInput"
                    id="broadcastStartTime"
                    format={format}
                    use12Hours
                    minuteStep={5}
                    sx={{ mt: 2, mb: 2 }}
                    disabled={eventLiveStatus}
                  />
                  <label htmlFor="broadcastStartTime">
                    Broadcast Start Time
                  </label>
                </div>
                <div className="time-box">
                  <TimePicker
                    value={dispatchEndTime}
                    onChange={onEndChange}
                    allowEmpty
                    label="Broadcast End Time"
                    showSecond={false}
                    defaultValue={now}
                    className="timeInput"
                    id="broadcastEndTime"
                    format={format}
                    use12Hours
                    minuteStep={5}
                    inputReadOnly
                    sx={{ mt: 2, mb: 2 }}
                  />
                  <label htmlFor="broadcastStartTime">Broadcast End Time</label>
                </div>
                <Button
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 1 }}
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default EditBroadcast;
