import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useState } from "react";
import { useNavigate as useHistory } from "react-router-dom";
import "../Signin.scss";
// import { makeStyles } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useLocation } from "react-router";
import CONSTANTS from "../../constants";
import Header from "../Shared/Header";
import Dailog from '../Shared/Dailog'
import axios from "../Shared/axios";

import requests from "../Shared/requests";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
let rtmpId;

const VenueDashboard = (props) => {
  const { classes } = props;
  const history = useHistory();
  const location = useLocation();
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState();
  const [deleteVenue, setDeleteVenue] = useState(false);
  const [playerSrc, setPlayerSrc] = useState("");
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [venueData, setVenueData] = useState({
    incomingRTMP: location?.state?.detail?.venueRtmpUrl,
    venueLocation: location?.state?.detail?.location,
    timeZone: location?.state?.detail?.timeZone,
    userId: location?.state?.detail?.userId,
    venueId: location?.state?.detail?.venueId,
    venueName: location?.state?.detail?.venueName,
    VenueUsername: location?.state?.detail?.username,
    venueipCameraUrl: location?.state?.detail?.ipCameraUrl,
    venuepassword: location?.state?.detail?.password,
  });
  const heading = location?.state?.detail?.venueName.toUpperCase();
  useEffect(() => {
    let arr = location?.state?.detail?.venueRtmpUrl.split("/");
    if (arr && arr.length > 0) {
      rtmpId = arr[arr.length - 1];
      setPlayerSrc(
        requests.playerSRC +
          location?.state?.detail?.userId +
          requests.configURl +
          rtmpId
      );
    }
  }, [location]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVenueData((preValue) => {
      return { ...preValue, [name]: value };
    });
  };
  useEffect(() => {
    if (location?.state?.detail?.ipCameraEnable === true) {
      setIsToggleOn(true);
    }
  }, [location]);

  const handleToggleChange = () => {
    setIsToggleOn((prevState) => !prevState);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      incomingRtmpUrl: rtmpId ? rtmpId : null,
      location: data.get("venueLocation"),
      userId: location?.state?.detail?.userId,
      venueName: data.get("venueName"),
      VenueUsername: location?.state?.detail?.username,
      venueipCameraUrl: location?.state?.detail?.ipCameraUrl,
      venuepassword: location?.state?.detail?.password,
    };
    try {
      const request = await axios.put(
        requests.createVenue + "/" + `${venueData.venueId}`,
        formData
      );
      setHandlePopUp(true);
      setPopUpMessage(CONSTANTS.VARIABLES.VENUE_EDITED_SUCCESS);
      event.target.reset();
      setTimeout(() => {
        history(-1);
      }, 2000);
    } catch (error) {
      if (error?.response && error?.response.status === 403) {
        handleLogout();
      }
      console.log(error);
    }
  };
  useEffect(() => {
    console.log("Location State:", location.state);
    console.log(
      "Extracted ipCameraUrl:",
      location?.state?.detail?.ipCameraEnable
    );
  }, [location]);
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };
  const customerListHandler = () => {
    history("/admin/customerlist");
  };
  const newCustomer = () => {
    history("/newcustomer");
  };

  const handleEditVenue = () => {
    history({
      pathname: "/editVenue",
      state: { detail: location?.state?.detail },
    });
  };

  const menuList = [
    {
      menuItem: "New Customer",
      handle: newCustomer,
    },
    {
      menuItem: "Customer List",
      handle: customerListHandler,
    },
    {
      menuItem: "Edit",
      handle: handleEditVenue,
    },
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const handleCancel = () => {
    setTimeout(() => {
      setDeleteVenue(false);
    }, 2000);
  };
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };

  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                style={classes.boxWidth}
                noValidate
                fullWidth
                onSubmit={handleSubmit}
                sx={{ mt: 1, minHeight: "26rem", height: "auto" }}
              >
                <TextField
                  margin="normal"
                  required
                  id="venueId"
                  label="Venue ID"
                  name="venueId"
                  fullWidth
                  value={venueData.venueId}
                  style={classes.inputFieldBold}
                  size="small"
                  color="error"
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  required
                  id="venueName"
                  label="Venue Name"
                  name="venueName"
                  fullWidth
                  size="small"
                  value={venueData.venueName}
                  style={classes.inputFieldBold}
                  color="error"
                  onChange={(e) => handleChange(e)}
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  required
                  id="venueLocation"
                  label="Venue Location"
                  name="venueLocation"
                  fullWidth
                  size="small"
                  color="error"
                  value={venueData.venueLocation}
                  onChange={(e) => handleChange(e)}
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  required
                  id="venueTimeZone"
                  label="Venue Time Zone"
                  name="venueTimeZone"
                  fullWidth
                  size="small"
                  color="error"
                  value={venueData.timeZone}
                  onChange={(e) => handleChange(e)}
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  required
                  id="incomingRTMP"
                  label="Incoming RTMP"
                  name="incomingRTMP"
                  fullWidth
                  size="small"
                  color="error"
                  value={venueData.incomingRTMP}
                  title={venueData.incomingRTMP}
                  onChange={(e) => handleChange(e)}
                  disabled={true}
                />
                {/* <div>
                  <div
                    className="activ-inactive"
                    style={{
                      paddingLeft: "0px",
                      gap: 90,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span>Is IP Camera Added?</span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="active"
                        id="active"
                        role="switch"
                        tabIndex={0}
                        checked={isToggleOn}
                        onChange={handleToggleChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        disabled
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  {isToggleOn && (
                    <>
                      <TextField
                        margin="normal"
                        required
                        id="venueipCameraUrl"
                        label="IP Camera url"
                        name="venueipCameraUrl"
                        fullWidth
                        size="small"
                        color="error"
                        value={venueData.venueipCameraUrl}
                        onChange={handleChange}
                        disabled={true}
                      />
                      <TextField
                        margin="normal"
                        required
                        id="VenueUsername"
                        label="Username"
                        name="VenueUsername"
                        fullWidth
                        size="small"
                        color="error"
                        value={venueData.VenueUsername}
                        onChange={handleChange}
                        disabled={true}
                      />
                      <TextField
                        margin="normal"
                        required
                        id="venuepassword"
                        label="Password"
                        name="venuepassword"
                        type="password"
                        fullWidth
                        size="small"
                        color="error"
                        value={venueData.venuepassword}
                        onChange={handleChange}
                        disabled={true}
                      />
                    </>
                  )}
                </div> */}
                <div>
                  <div
                    className="activ-inactive"
                    style={{
                      paddingLeft: "0px",
                      gap: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        minWidth: "200px",
                      }}
                    >
                      <span>Is IP Camera Added?</span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="active"
                          id="active"
                          role="switch"
                          tabIndex={0}
                          checked={isToggleOn}
                          onChange={handleToggleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          disabled
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div style={{ width: "40px", textAlign: "center" }}>
                      {isToggleOn ? (
                        <Tooltip title="View IP Camera Details">
                          <IconButton color="primary" onClick={handleOpen}>
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              style={{ color: "black" }}
                            />{" "}
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <div style={{ width: "24px", height: "24px" }} />
                      )}
                    </div>
                  </div>

                  <Dialog open={open} onClose={handleClose}  maxWidth="xs">
                    <DialogTitle>IP Camera Details</DialogTitle>
                    <DialogContent>
                      <TextField
                        margin="normal"
                        required
                        id="venueipCameraUrl"
                        label="IP Camera URL"
                        name="venueipCameraUrl"
                        fullWidth
                        size="small"
                        color="error"
                        value={venueData.venueipCameraUrl}
                        title={venueData.venueipCameraUrl}
                        onChange={handleChange}
                        disabled={true}
                      />
                      <TextField
                        margin="normal"
                        required
                        id="VenueUsername"
                        label="Username"
                        name="VenueUsername"
                        fullWidth
                        size="small"
                        color="error"
                        value={venueData.VenueUsername}
                        onChange={handleChange}
                        disabled={true}
                      />
                      <TextField
                        margin="normal"
                        required
                        id="venuepassword"
                        label="Password"
                        name="venuepassword"
                        type="text"
                        fullWidth
                        size="small"
                        color="error"
                        value={venueData.venuepassword}
                        onChange={handleChange}
                        disabled={true}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
                <div className="rtmp-preview-box">
                  <iframe
                    title="liveStream"
                    width="100%"
                    height="100%"
                    src={playerSrc}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default VenueDashboard;
