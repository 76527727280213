import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import Header from "../Shared/Header";
import { useLocation, useNavigate } from "react-router";
import TimePicker from "rc-time-picker";
import moment from "moment";
import axios from "../Shared/axios";
import requests from "../Shared/requests";

const DailyTemplate = ({ classes }) => {
  const [dispatchTime, setDispatchTime] = useState();
  const [dispatchEndTime, setDispatchEndTime] = useState();
  const [activeStatus, setActiveStatus] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId") || "defaultVenueId";
  const format = "hh:mm A";

  const validateTime = () => {
    if (
      +dispatchEndTime <= +dispatchTime ||
      !dispatchEndTime ||
      !dispatchTime
    ) {
      setErrorMessage("END TIME NEEDS TO BE LATER THAN START TIME");
      setErrorPopupOpen(true);
      return;
    }
    handleSave();
  };

  const handleSave = async () => {
    const dto = {
      endTime: {
        hour: moment(dispatchEndTime).format("HH"),
        minute: moment(dispatchEndTime).format("mm"),
      },
      startTime: {
        hour: moment(dispatchTime).format("HH"),
        minute: moment(dispatchTime).format("mm"),
      },
      type: "daily",
      venueId: venueId,
    };

    try {
      await axios.post(requests.createTemplate, dto);
      setPopUpMessage("DAILY TEMPLATE EDITED SUCCESSFULLY");
      setSuccessPopupOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        handleLogout();
      } else {
        const errorMessageData = error.response.data.message;
        setErrorMessage(errorMessageData);
        setErrorPopupOpen(true);
      }
    }
  };

  const getMomentTime = (time) => {
    if (time) {
      const today = moment().format("YYYY-MM-DD");
      const momentInTime = moment(`${today} ${time}`);
      return momentInTime;
    } else {
      return null;
    }
  };

  useEffect(() => {
    setDispatchTime(getMomentTime(location?.state?.day?.startTime));
    setDispatchEndTime(getMomentTime(location?.state?.day?.endTime));
    setActiveStatus(location?.state?.day?.streamStatus);
  }, [location.state.day]);

  const handleSuccessClose = () => {
    setSuccessPopupOpen(false);
    navigate(`/BroadcastTemplate?venueId=${venueId}`);
  };

  const handleErrorClose = () => {
    setErrorPopupOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };

  const handleValueChange = (value) => {
    setDispatchTime(value);
  };

  const onEndChange = (value) => {
    setDispatchEndTime(value);
  };

  const menuList = [
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];

  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Header menuList={menuList} heading={"BROADCAST"} />
            <Box style={classes.boxWidth} sx={{ mt: 2 }}>
              <div className="customer-detail">
                <Typography className="detail"><b>Venue ID :</b> {venueId}</Typography>
              </div>
              <Typography
                variant="h6"
                align="center"
                sx={{ color: "black", my: 1 }}
              >
                Daily Template
              </Typography>
              <div className="time-box">
                <TimePicker
                  value={dispatchTime}
                  onChange={handleValueChange}
                  showSecond={false}
                  allowEmpty
                  minuteStep={5}
                  placeholder="hh:mm"
                  className="timeInput"
                  id="broadcastStartTime"
                  format={format}
                  use12Hours
                  sx={{ mt: 2, mb: 2 }}
                />
                <label htmlFor="broadcastStartTime">Broadcast Start Time</label>
              </div>
              <div className="time-box">
                <TimePicker
                  value={dispatchEndTime}
                  minuteStep={5}
                  onChange={onEndChange}
                  allowEmpty
                  showSecond={false}
                  placeholder="hh:mm"
                  className="timeInput"
                  id="broadcastEndTime"
                  format={format}
                  use12Hours
                  sx={{ mt: 2, mb: 2 }}
                />
                <label htmlFor="broadcastEndTime">Broadcast End Time</label>
              </div>
              <Button
                className="sign-in-button"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 4 }}
                onClick={validateTime}
              >
                SAVE
              </Button>
            </Box>
          </Box>
        </div>
      </Container>

      {/* Success Dialog */}
      <Dialog
        open={successPopupOpen}
        onClose={handleSuccessClose}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "20px",
            width: "500px",
            textAlign: "center",
          },
        }}
      >
        <Typography variant="h4" sx={{ mb: 2 }}>
          <b>
            DAILY TEMPLATE CREATED<br />
            SUCCESSFULLY
          </b>
        </Typography>
        <DialogActions>
          <Button
            onClick={handleSuccessClose}
            variant="contained"
            sx={{
              backgroundColor: "red",
              color: "white",
              width: "100%",
              padding: "10px",
              "&:hover": {
                backgroundColor: "red",
              },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Dialog */}
      <Dialog
        open={errorPopupOpen}
        onClose={handleErrorClose}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "20px",
            width: "500px",
            textAlign: "center",
          },
        }}
      >
        <DialogContent>
          <Typography variant="h4" sx={{ mb: 2 }}>
            <b>
              {errorMessage}
            </b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} color="primary" autoFocus sx={{
            backgroundColor: "red",
            color: "white",
            width: "100%",
            padding: "10px",
            "&:hover": {
              backgroundColor: "red",
            },
          }}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DailyTemplate;
