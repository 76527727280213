let loginBaseURL = "/gamecast-api/user";
let test = "http://13.57.169.199/gamecast-api/user/auth/authorize?userId";
let baseURL = "gamecast-api/v1/gamecast";
// let baseURL = "/gamecast";
let mediaURL = "/gamecast-api/v1/antMedia";
// let mediaURL = "/antMedia";
let srcDomain = "https://app.gamecast.info:5443/";
let configURl = "/play.html?name=";
let RtmpDomain = "rtmp://app.gamecast.info"

const requests = {
  // login: loginBaseURL + "/auth/authorize",
  login: baseURL + "/user/login",
  updateWeekly: baseURL + "/schedule-template/template/update",
  logout: baseURL + "/user/logout",
  createWeeklySchedule: baseURL + "/venue/event/weekly/schedule",
  getUserIdAndPassword: baseURL + "/user/generate",
  updateTemplate: baseURL + "/schedule-template/day/update",
  userHandler: baseURL + "/user",
  getEditWeeklyTemplate: baseURL + "/schedule-template/template/",
  updateScheduleTemplate: baseURL + "/schedule-template/update",
  getAllUsers: baseURL + "/users",
  getTemplate: baseURL + "/schedule-template",
  getWeeklyTemplate: baseURL + "/schedule-template/template/",
  createWeeklyTemplate: baseURL + "/schedule-template/create",
  createTemplate: baseURL + "/schedule-template/template/create",
  deleteTemplate: baseURL + "/schedule-template",
  createVenue: baseURL + "/user/venue",
  getAllVenues: baseURL + "/user/venues",
  accoutStatus: baseURL + "/user/accountStatus",
  createRtmpUrl: mediaURL + "/broadcast/create",
  // deleteRtmpUrl: mediaURL + "/broadcplayerURLast/delete",  // not being used
  generateVenueId: baseURL + "/user/venue/generate",
  venueBroadccastDetail: mediaURL + "/multiBroadcast/details",
  broadcastIncomingStream: mediaURL + "/broadcast",
  scheduleVenueEvents: baseURL + "/venue/event/schedule",
  configureSocialMediaRtmpUrl: mediaURL + "/multibroadcast/configure",
  broadcastGolive: mediaURL + "/broadcast/live",
  broadcastStopLive: mediaURL + "/broadcast/stop",
  getAllSheduleEvents: baseURL + "/venue/events",
  deleteBroadcastEvent: baseURL + "/venue/event/delete",
  resheduleEvent: baseURL + "/venue/event/reschedule",
  timeShedular: baseURL + "/venue/event/time",
  version: baseURL + "/version",
  scheduleSetting: baseURL + "/setting",
  weekdaySetting: baseURL + "/setting/weekday",
  manualStreamLimit: baseURL + "/manualStreamLimit",
  getLiveStatus: mediaURL + '/broadcast/status',
  playerSRC: srcDomain,
  configURl: configURl,
  RtmpDomain: RtmpDomain
};

export default requests;
