import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Shared/Header";
import { Navigate, useLocation, useNavigate } from "react-router";
import TimePicker from "rc-time-picker";
import moment from "moment";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import Dailog from "../Shared/Dailog";
import AlertDialogSlide from "../Shared/Dailog";
import { useDispatch, useSelector } from "react-redux";
import { setTemplate } from "../../redux/actions";
import DailyTemplate from "./DailyTemplate";

const EditWeeklySet = ({ classes }) => {
  const [dispatchTime, setDispatchTime] = useState();
  const [dispatchEndTime, setDispatchEndTime] = useState();
  const [dailyTemplates, setDailyTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [errorTime, setErrorTime] = useState(false);
  const [errorDailog, setErrorDailog] = useState(false);
  const [dispatchTimes, setDispatchTimes] = useState([
    {
      start: null,
      end: null,
      id: 0,
    },
  ]);
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const dispatch = useDispatch();
  const weekTemplateData = useSelector((state) => state.template);
  const history = useNavigate();
  const location = useLocation();
  const format = "hh:mm A";
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId") || "defaultVenueId";
  const templateId = searchParams.get("templateId");
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          console.error("No access token found in localStorage");
          return;
        }

        const response = await axios.get(
          requests.getTemplate + "?" + `venueId=${venueId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          const data = response.data.data;
          setDailyTemplates(data.daily || []);
          const selectedWeeklyTemplate = data.weekly.find(
            (template) => template.id === parseInt(templateId)
          );

          if (selectedWeeklyTemplate) {
            const currentDaySchedule =
              selectedWeeklyTemplate.scheduleTemplateDetailDTOS.find(
                (detail) => detail.dayOfWeek === location?.state?.day?.weekday
              );

            if (currentDaySchedule) {
              const timeDetails = currentDaySchedule.startTimeAndEndTimeDTOS;

              if (timeDetails && timeDetails.length > 0) {
                const timePairs = timeDetails.map((timePair) => {
                  const start = moment()
                    .hour(timePair.startTime.hour)
                    .minute(timePair.startTime.minute);
                  const end = moment()
                    .hour(timePair.endTime.hour)
                    .minute(timePair.endTime.minute);

                  return {
                    start,
                    end,
                    id: timePair.id,
                  };
                });

                setDispatchTimes(timePairs);
              } else {
                console.error("No time details available.");
              }
            }
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response.status === 403) {
          history("/");
        } else {
          console.error("Error during API call:", error);
        }
      }
    };

    fetchTemplates();
  }, [venueId, templateId, location?.state?.day?.weekday]);
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          console.error("No access token found in localStorage");
          return;
        }
        const response = await axios.get(
          requests.getTemplate + "?" + `venueId=${venueId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          const data = response.data.data;
          setDailyTemplates(data.daily || []);
          console.log("dailyTemplates:", data.daily);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 403) {
            history("/");
          } else {
            console.error("Error response:", error.response.data);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
    };
    fetchTemplates();
  }, [venueId]);
  const handleStartTimeChange = (value, index) => {
    const updatedTimes = [...dispatchTimes];
    console.log(dispatchTimes, "disi");
    updatedTimes[index].start = value;
    setDispatchTimes(updatedTimes);
  };

  const handleEndTimeChange = (value, index) => {
    const updatedTimes = [...dispatchTimes];
    updatedTimes[index].end = value;
    setDispatchTimes(updatedTimes);
  };

  const handleTemplateChange = (event, index, id) => {
    const selectedTemplateId = event.target.value;
    debugger;
    const newSelectedTemplates = [...selectedTemplates];
    newSelectedTemplates[index] = selectedTemplateId;
    setSelectedTemplates(newSelectedTemplates);

    const template = dailyTemplates.find((t) => t.id === selectedTemplateId);

    if (template && template.scheduleTemplateDetailDTOS.length > 0) {
      const scheduleDetail = template.scheduleTemplateDetailDTOS[0];
      const timeDetails = scheduleDetail?.startTimeAndEndTimeDTOS?.[0];
      console.log(template, "aeeeooo");

      if (timeDetails) {
        const { startTime, endTime } = timeDetails;
        if (startTime && endTime) {
          const start = moment().hour(startTime.hour).minute(startTime.minute);
          const end = moment().hour(endTime.hour).minute(endTime.minute);

          const updatedTimes = [...dispatchTimes];
          updatedTimes[index] = {
            start,
            end,
            id: id,
          };

          setDispatchTimes(updatedTimes);
        } else {
          console.error("Start or end time is missing.");
        }
      } else {
        console.error("No time details available.");
      }
    } else {
      console.error("No valid schedule template found.");
    }
  };

  const handleSubmit = async (event) => {
    const hasEmptyTimePair = dispatchTimes.some((timePair) => {
      return !timePair.start || !timePair.end;
    });

    if (hasEmptyTimePair) {
      setErrorDailog(true);
      return;
    }

    const timeData = {
      dayOfWeek: location?.state?.day?.weekday,
      id: templateId,
      startTimeAndEndTimeDTOS: dispatchTimes.map((timePair) => ({
        startTime: {
          hour: timePair.start.hour(),
          minute: timePair.start.minute(),
        },
        id: timePair.id,
        endTime: {
          hour: timePair.end.hour(),
          minute: timePair.end.minute(),
        },
      })),
    };

    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        console.error("No access token found in localStorage");
        return;
      }

      const response = await axios.put(requests.updateTemplate, timeData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        history(
          `/EditWeeklyTemplate?venueId=${venueId}&templateId=${templateId}`
        );
      } else {
        setHandlePopUp(true);
        setPopUpMessage(response.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setHandlePopUp(true);
      setPopUpMessage("AN ERROR OCCURRED WHILE SAVING THE TEMPLATE");
    }
  };
  const handleAddTime = () => {
    setDispatchTimes([
      ...dispatchTimes,
      {
        start: null,
        end: null,
        id: 0,
      },
    ]);
  };

  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Header
              menuList={[
                { menuItem: "Logout", handle: () => history("/admin/") },
              ]}
              heading={"EDIT WEEKLY SCHEDULE"}
            />
            {handlePopUp && (
              <Dailog
                handleCancel={() => setHandlePopUp(false)}
                errorDailog={popUpMessage}
              />
            )}
            {errorTime && (
              <AlertDialogSlide
                handleCancel={() => setErrorTime(false)}
                errorTime="PLEASE SELECT AT LEAST ONE VALID BROADCAST TIME SLOT"
              />
            )}
            {errorDailog && (
              <AlertDialogSlide
                handleCancel={() => setErrorDailog(false)}
                errorDailog="PLEASE SELECT AT LEAST ONE VALID BROADCAST TIME SLOT"
              />
            )}
            <Box style={classes?.boxWidth}>
              <Typography
                variant="h6"
                align="center"
                sx={{ color: "red", my: 3 }}
              >
                {location?.state?.day?.weekday}
              </Typography>

              {/* Time picker fields */}
              <div>
                {dispatchTimes.length > 0 ? (
                  dispatchTimes.map((timePair, index) => (
                    <div key={index} className="time-pair">
                      <div
                        style={{
                          border: "1px solid black",
                          borderRadius: "8px",
                          padding: "10px",
                          marginBottom: "10px",
                          display: "flex",
                          flexDirection: "column",
                          // alignItems: "center",
                        }}
                      >
                        <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                          <InputLabel id={`template-select-label-${index}`}>
                            Select Daily Template
                          </InputLabel>
                          <Select
                            labelId={`template-select-label-${index}`}
                            value={selectedTemplates[index] || ""}
                            label="Select Daily Template"
                            onChange={(event) =>
                              handleTemplateChange(event, index, timePair.id)
                            }
                          >
                            {dailyTemplates
                              .sort((a, b) => {
                                const timeDetailsA =
                                  a.scheduleTemplateDetailDTOS[0]
                                    ?.startTimeAndEndTimeDTOS[0];
                                const timeDetailsB =
                                  b.scheduleTemplateDetailDTOS[0]
                                    ?.startTimeAndEndTimeDTOS[0];

                                const startTimeA = moment()
                                  .hour(timeDetailsA?.startTime.hour)
                                  .minute(timeDetailsA?.startTime.minute);
                                const startTimeB = moment()
                                  .hour(timeDetailsB?.startTime.hour)
                                  .minute(timeDetailsB?.startTime.minute);

                                return startTimeA.isBefore(startTimeB) ? -1 : 1;
                              })
                              .map((template) => {
                                const scheduleDetail =
                                  template.scheduleTemplateDetailDTOS[0];
                                const timeDetails =
                                  scheduleDetail?.startTimeAndEndTimeDTOS[0];

                                return (
                                  <MenuItem
                                    key={template.id}
                                    value={template.id}
                                  >
                                    {timeDetails
                                      ? `${moment()
                                        .hour(timeDetails.startTime.hour)
                                        .minute(timeDetails.startTime.minute)
                                        .format("hh:mm A")} - ${moment()
                                          .hour(timeDetails.endTime.hour)
                                          .minute(timeDetails.endTime.minute)
                                          .format("hh:mm A")}`
                                      : "No Time Available"}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>

                        {/* Time Picker for Start and End Times */}
                        <div className="time-box">
                          <TimePicker
                            value={timePair.start}
                            onChange={(value) =>
                              handleStartTimeChange(value, index)
                            }
                            showSecond={false}
                            allowEmpty
                            minuteStep={5}
                            placeholder="hh:mm A"
                            format="hh:mm A"
                            use12Hours
                            sx={{ mt: 2, mb: 2 }}
                            className="timeInput"
                            id={`broadcastStartTime-${index + 1}`}
                          />
                          <label htmlFor={`broadcastStartTime-${index + 1}`}>
                            Broadcast Start Time {index + 1}
                          </label>
                        </div>
                        <div className="time-box">
                          <TimePicker
                            value={timePair.end}
                            onChange={(value) =>
                              handleEndTimeChange(value, index)
                            }
                            minuteStep={5}
                            allowEmpty
                            showSecond={false}
                            placeholder="hh:mm A"
                            format="hh:mm A"
                            use12Hours
                            sx={{ mt: 2, mb: 2 }}
                            className="timeInput"
                            id={`broadcastEndTime-${index + 1}`}
                          />
                          <label htmlFor={`broadcastEndTime-${index + 1}`}>
                            Broadcast End Time {index + 1}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <Typography></Typography>
                )}
              </div>

              <Button
                className="sign-in-button"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 0 }}
                onClick={handleAddTime}
              >
                 ADD BROADCASTS
              </Button>
              <Button
                className="sign-in-button"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 4 }}
                onClick={handleSubmit}
              >
                SAVE
              </Button>
            </Box>
          </Box>
        </div>
      </Container>
    </div>
  );
};

export default EditWeeklySet;
