import { useLocation, useNavigate } from "react-router";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Header from "../Shared/Header";
import { Button, ButtonBase, Grid, Typography } from "@mui/material";
import { weekdays } from "../../constants/constants";
import { List, ListItem } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "../Shared/axios";
import moment from "moment";
import requests from "../Shared/requests";

const SystemSchedule = ({ classes }) => {
  const [settingId, setSettingId] = useState(0);
  const [activeStatus, setActiveStatus] = useState(false);
  const [weekdaySetting, setWeekdaySetting] = useState(weekdays);
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getScheduleSettings(location?.state?.detail?.userId);
    return () => {};
  }, []);

  async function getScheduleSettings(userId) {
    try {
      const res = await axios.get(requests.scheduleSetting, {
        params: { userId: userId },
      });
      setWeekdaySetting(res?.data?.data?.weekdaySetting);
      setActiveStatus(res?.data?.data?.streamStatus);
      setSettingId(res?.data?.data?.id);
    } catch (error) {
      console.log(error.message);
    }
  }

  const getFormatedTime = (day) => {
    const today = moment().format("YYYY-MM-DD");
    const startTime = moment(`${today} ${day?.startTime}`).format("hh:mm a");
    const endTime = moment(`${today} ${day?.endTime}`).format("hh:mm a");
    return `${startTime} - ${endTime}`;
  };

  const handleSave = async () => {
    const dto = {
      endDate: "",
      startDate: "",
      settingId: settingId,
      streamStatus: activeStatus,
      userId: location?.state?.detail?.userId,
    };
    try {
      const res = await axios.put(requests.scheduleSetting, dto);
      history(-1);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleActive = () => {
    setActiveStatus((status) => !status);
  };

  const handleDayClick = (day) => {
    history("/admin/daySetting", {
      state: {
        detail: location?.state?.detail,
        day: day,
      },
    });
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/admin/");
  };
  const customerListHandler = () => {
    history("/admin/customerlist");
  };
  const newCustomer = () => {
    history("/admin/newcustomer");
  };
  const handleEditCustomer = () => {
    history("/admin/editCustomer", {
      state: { detail: location?.state?.detail },
    });
  };

  const menuList = [
    {
      menuItem: "New Customer",
      handle: newCustomer,
    },
    {
      menuItem: "Customer List",
      handle: customerListHandler,
    },
    {
      menuItem: "Edit Customer",
      handle: handleEditCustomer,
    },
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Header menuList={menuList} heading={"SYSTEM SCHEDULE"} />
            <Box
              component="form"
              style={classes.boxWidth}
              noValidate
              sx={{ width: "100%" }}
            >
              <Typography className="details" align="center" sx={{ my: 2 }}>
                Set system schedule during active season
              </Typography>
              <Box
                sx={{ border: 1, borderRadius: "1rem", borderColor: "gray" }}
              >
                <List>
                  {weekdaySetting?.map((day) => (
                    <ListItem
                      key={day.id}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              color: !day["streamStatus"] ? "gray" : "black",
                            }}
                          >
                            {day.weekday.slice(0, 3)}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography
                            sx={{
                              color: !day["streamStatus"] ? "gray" : "black",
                            }}
                          >
                            {day.streamStatus ? getFormatedTime(day) : "OFF"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <ButtonBase
                            onClick={() => handleDayClick(day)}
                            size="small"
                            sx={{
                              px: 1,
                              borderRadius: "1rem",
                              backgroundColor: "black",
                              color: "#fff",
                              ml: 2,
                            }}
                          >
                            SET
                          </ButtonBase>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Typography
                className="details"
                align="center"
                sx={{ mt: 4, mb: 2 }}
              >
                Turn system off during off-season
              </Typography>
              <div className="activ-inactive">
                <Typography variant="h6">SYSTEM ON/OFF</Typography>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={activeStatus}
                    name="active"
                    id="active"
                    onChange={(e) => handleActive(e)}
                  />
                  <span className="slider round"></span>
                </label>
                <Typography variant="body2">
                  {activeStatus ? "on" : "off"}
                </Typography>
              </div>
              <Button
                fullWidth
                className="sign-in-button"
                variant="contained"
                sx={{ mt: 2, mb: 4 }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        </div>
      </Container>
    </div>
  );
};

export default SystemSchedule;
