import { combineReducers } from 'redux';
import { loginReducer } from './loginReducer';
import { userReducer } from './userReducer';
import { templateReducer } from './template';


const appReducers = combineReducers({
  user: loginReducer,
  users: userReducer,
  template: templateReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }
  return appReducers(state, action);
} 

export default rootReducer;