import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Container,
  CssBaseline,
  Box,
  Typography,
  Button,
  ButtonBase,
  Grid,
  TextField,
  List,
  ListItem,
  Dialog,
  DialogContent,
} from "@mui/material";
import Header from "../Shared/Header";
import { weekdays } from "../../constants/constants";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import { useDispatch, useSelector } from "react-redux";

const WeeklyTemplate = ({ classes }) => {
  const [settingId, setSettingId] = useState(0);
  const [activeStatus, setActiveStatus] = useState(false);
  const [weekdaySetting, setWeekdaySetting] = useState(weekdays);
  const [templateName, setTemplateName] = useState("");
  const [apiData, setApiData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // State for dialog
  const history = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId") || "defaultVenueId";
  const templateId = searchParams.get("templateId");
  const dispatch = useDispatch();
  const weekTemplateData = useSelector((state) => state.template);

  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const response = await axios.get(
          requests.getEditWeeklyTemplate + `${templateId}`
        );
        setApiData(response.data);
        setTemplateName(response.data.data.templateName);

        const updatedWeekdaySetting = weekdays.map((day) => {
          const templateDay = response.data.scheduleTemplateDetailDTOS.find(
            (template) => template.dayOfWeek === day.weekday.toUpperCase()
          );
          if (templateDay) {
            return {
              ...day,
              startTime: templateDay.startTime
                ? `${templateDay.startTime.hour}:${templateDay.startTime.minute}`
                : null,
              endTime: templateDay.endTime
                ? `${templateDay.endTime.hour}:${templateDay.endTime.minute}`
                : null,
              off: templateDay.off,
            };
          }
          return day;
        });
        setWeekdaySetting(updatedWeekdaySetting);
        localStorage.setItem("template", updatedWeekdaySetting);
      } catch (error) {
        console.error("Error fetching template data:", error);
      }
    };

    fetchTemplateData();
  }, [templateId]);

  const handleSave = async () => {
    if (!templateName.trim()) {
      return;
    }
    const scheduleTemplateDetailDTOS = weekdaySetting.map((day) => ({
      dayOfWeek: day.weekday.toUpperCase(),
      id: day.id || 0,
      off: !day.startTime || !day.endTime,
      startTimeAndEndTimeDTOS: [
        {
          id: 0,
          startTime: {
            hour: day.startTime ? parseInt(day.startTime.split(":")[0], 10) : 0,
            minute: day.startTime
              ? parseInt(day.startTime.split(":")[1], 10)
              : 0,
          },
          endTime: {
            hour: day.endTime ? parseInt(day.endTime.split(":")[0], 10) : 0,
            minute: day.endTime ? parseInt(day.endTime.split(":")[1], 10) : 0,
          },
        },
      ],
    }));

    const dto = {
      id: templateId || 0,
      scheduleTemplateDetailDTOS: apiData.data.scheduleTemplateDetailDTOS,
      templateName: templateName.trim(),
      type: "weekly",
      venueId: venueId || "defaultVenueId",
    };

    try {
      const res = await axios.put(requests.updateWeekly, dto);
      console.log("Save Successful:", res.data);
      setOpenDialog(true);
    } catch (error) {
      console.error("Error saving template:", error.message);
    }
  };

  const handleDayClick = (day) => {
    const venueId = searchParams.get("venueId") || "defaultVenueId";
    history(
      `/Customer/EditWeeklySet?venueId=${venueId}&templateId=${templateId}`,
      {
        state: {
          detail: location?.state?.detail,
          day: day,
        },
      }
    );
  };

  const convertTo12HourFormat = (time) => {
    if (!time) return null;

    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);

    if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) {
        formattedHour -= 12;
      }
    } else if (formattedHour === 0) {
      formattedHour = 12;
    }
    const formattedMinute = minute.padStart(2, "0");
    return `${formattedHour}:${formattedMinute} ${period}`;
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    history(`/BroadcastTemplate?venueId=${venueId}`); // Redirect after dialog close
  };

  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Header
              menuList={[
                {
                  menuItem: "Logout",
                  handle: () => localStorage.removeItem("access_token"),
                },
              ]}
              heading={"EDIT WEEKLY TEMPLATE"}
            />
            <Box
              component="form"
              style={classes.boxWidth}
              noValidate
              sx={{ width: "100%", mt: 1 }}
            >
              <div className="customer-detail">
                <Typography className="detail">
                  <b>Venue ID :</b> {venueId}
                </Typography>
              </div>
              <Typography className="details" align="center" sx={{ my: 1 }}>
                <b>Create Weekly Template</b>
              </Typography>
              <Box
                sx={{ border: 1, borderRadius: "1rem", borderColor: "gray" }}
              >
                <List>
                  {weekdaySetting?.map((day) => {
                    const templateDay =
                      apiData?.data?.scheduleTemplateDetailDTOS?.find(
                        (template) =>
                          template.dayOfWeek === day.weekday.toUpperCase()
                      );

                    return (
                      <ListItem
                        key={day.id}
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid container alignItems="flex-start">
                          <Grid
                            item
                            xs={3}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: templateDay?.startTimeAndEndTimeDTOS
                                ?.length
                                ? "32px"
                                : "auto",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 600,
                                color: templateDay?.off ? "gray" : "black",
                              }}
                            >
                              {day.weekday.slice(0, 3)}
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            {templateDay?.off ||
                            !templateDay?.startTimeAndEndTimeDTOS?.length ? (
                              <Typography
                                sx={{ color: "gray", fontWeight: 600 }}
                              >
                                OFF
                              </Typography>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "0px",
                                  alignItems: "flex-start",
                                }}
                              >
                                {templateDay.startTimeAndEndTimeDTOS
                                  .sort((a, b) => {
                                    const aStartTime = new Date(
                                      0,
                                      0,
                                      0,
                                      a.startTime?.hour,
                                      a.startTime?.minute
                                    );
                                    const bStartTime = new Date(
                                      0,
                                      0,
                                      0,
                                      b.startTime?.hour,
                                      b.startTime?.minute
                                    );
                                    return aStartTime - bStartTime;
                                  })
                                  .map((game, index) => (
                                    <Typography
                                      key={game.id}
                                      sx={{
                                        border: "1px solid black",
                                        borderRadius: "8px",
                                        padding: "4px 10px",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        whiteSpace: "nowrap",
                                        width: "150px",
                                        marginTop: index === 0 ? "0px" : "6px",
                                      }}
                                    >
                                      {convertTo12HourFormat(
                                        `${game.startTime.hour}:${game.startTime.minute}`
                                      )}{" "}
                                      -{" "}
                                      {convertTo12HourFormat(
                                        `${game.endTime.hour}:${game.endTime.minute}`
                                      )}
                                    </Typography>
                                  ))}
                              </Box>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: templateDay?.startTimeAndEndTimeDTOS
                                ?.length
                                ? "30px"
                                : "24px",
                            }}
                          >
                            <ButtonBase
                              onClick={() => handleDayClick(day)}
                              size="small"
                              sx={{
                                px: 1,
                                borderRadius: "1rem",
                                backgroundColor: "black",
                                color: "#fff",
                              }}
                            >
                              Select
                            </ButtonBase>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
              <TextField
                size="small"
                label="Template Name Input"
                fullWidth
                required
                sx={{ mt: 2 }}
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                error={templateName === ""}
                helperText={templateName === "" ? "This field is required" : ""}
              />

              <Button
                fullWidth
                className="sign-in-button"
                variant="contained"
                sx={{ mt: 2, mb: 4 }}
                onClick={handleSave}
              >
                SAVE
              </Button>
            </Box>
          </Box>
        </div>
      </Container>

      {/* Dialog Box */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            // padding: "10px",
            width: "500px",
            textAlign: "center",
          },
        }}
      >
        <DialogContent
          sx={{
            textAlign: "center",
            padding: "2rem",
          }}
        >
          <Typography variant="h4" sx={{ mb: 2 }}>
            <b>WEEKLY TEMPLATE UPDATED SUCCESSFULLY</b>
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red",
              color: "white",
              width: "100%",
              padding: "10px",
              "&:hover": {
                backgroundColor: "red",
              },
            }}
            onClick={handleDialogClose}
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WeeklyTemplate;
