import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate as useHistory, useLocation } from "react-router-dom";
import CONSTANTS from "../../constants";
import Dailog from "../Shared/Dailog";
import Header from "../Shared/Header";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import "../Signin.scss";

const format = "hh:mm A";
const heading = "NEW BROADCAST";
// var moment = require("moment-timezone");
let now;
let startTime;

const EditDailyBroadcast = (props) => {
  const location = useLocation();
  const { classes } = props;
  const [shedulerValues, setShedulerValues] = useState({
    broadcastName: "",
  });
  const [popUpMessage, setPopUpMessage] = useState();
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const history = useHistory();
  const [errorDailog, setErrorDailog] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [dispatchTime, setDispatchTime] = useState();
  const [dispatchEndTime, setDispatchEndTime] = useState();
  const [venueName, setVenueName] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const templateId = searchParams.get("templateId");
  const venueId = searchParams.get("venueId");
  const timezone = localStorage.getItem('timezone');
  //   const url = new URL(window.location.href);
  // const searchParams = new URLSearchParams(url.search);

  // const templateId = searchParams.get("templateId");
  // const venueId = searchParams.get("venueId");

  // useEffect(() => {
  //   newBroad();
  // }, []);

  // const newBroad = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       requests.timeShedular +
  //         "?" +
  //         `timezone=${timezone}`
  //     );
  //     now = moment(data?.data?.date + " " + data?.data?.endTime);
  //     startTime = moment(data?.data?.date + " " + data?.data?.startTime);
  //     setShedulerValues({
  //       broadcastName: "",
  //     });
  //     setStartDate(new Date(data?.data?.date + " " + data?.data?.startTime));
  //     setDispatchTime(startTime);
  //     setDispatchEndTime(now);
  //   } catch (error) {
  //     if (error.response.status === 403) {
  //       handleLogout();
  //     }
  //     console.log(error);
  //   }
  // };
  useEffect(() => {
    if (!templateId) return;
  
    axios
      .get(
        requests.getTemplate +
          "?" +
          `venueId=${venueId}&templateId=${templateId}`
      )
      .then((response) => {
        if (response.data.success) {
          const dailyTemplate = response.data.data.daily.find(
            (template) => template.id === parseInt(templateId)
          );
  
          if (dailyTemplate) {
            const scheduleDetails = dailyTemplate.scheduleTemplateDetailDTOS[0];
            const startTimeEndTime = scheduleDetails.startTimeAndEndTimeDTOS[0];
  
            if (startTimeEndTime) {
              const startTime = moment()
                .set("hour", startTimeEndTime.startTime.hour)
                .set("minute", startTimeEndTime.startTime.minute);
              const endTime = moment()
                .set("hour", startTimeEndTime.endTime.hour)
                .set("minute", startTimeEndTime.endTime.minute);
  
              setDispatchTime(startTime);
              setDispatchEndTime(endTime);
            }
            setVenueName(dailyTemplate.venueName);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching daily template data:", error);
      });
  }, [venueId, templateId]);  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setShedulerValues((preValue) => {
      return { ...preValue, [name]: value };
    });
  };
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };

  const handleCancel = () => {
    setErrorDailog(false);
  };

  const menuList = [
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];

  const handleValueChange = (value) => {
    setDispatchTime(value);
  };

  const handleCloseSuccess = () => {
    setSuccessMessage("");
    history(`/Broadcasts?venueId=${venueId}`, {
      state: { detail: location?.state?.detail },
    });
  };

  const onEndChange = (value) => {
    setDispatchEndTime(value);
  };
  const handleDateChange = (value) => {
    setStartDate(value);
  };
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (shedulerValues.broadcastName) {
      let shedularStartDate = moment(startDate).format("YYYY-MM-DD");
      let shedulerStartTime = moment(dispatchTime).format("HH:mm");
      let shedularEndTime = moment(dispatchEndTime).format("HH:mm");
      try {
        let eventEnd = `${shedularStartDate}T${shedularEndTime}`;
        let eventStart = `${shedularStartDate}T${shedulerStartTime}`;
        const data = {
          eventName: shedulerValues.broadcastName,
          endDateTime: eventEnd,
          startDateTime: eventStart,
          venueId: venueId,
        };
        const request = await axios.post(requests.scheduleVenueEvents, data);
        setSuccessMessage(CONSTANTS.VARIABLES.EVENT_CREATED_SUCCESS);
      } catch (error) {
        console.log(error);
        if (error.response.status === 417) {
          setErrorDailog(error?.response?.data?.message);
        } else if (error.response.status === 403) {
          handleLogout();
        } else if (error.response?.statusText) {
          setPopUpMessage(error.response.statusText);
          setHandlePopUp(true);
        } else {
          setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
          setHandlePopUp(true);
        }
      }
    } else {
      setHandlePopUp(true);
      setPopUpMessage(CONSTANTS.VARIABLES.BROADCAST_NAME);
    }
  };
  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              {successMessage ? (
                <Dailog
                  handleCancel={handleCloseSuccess}
                  errorDailog={successMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                AM
                style={classes.boxWidth}
                noValidate
                onSubmit={handleSubmit}
                fullWidth
                sx={{ mt: 1 }}
              >
                {errorDailog ? (
                  <Dailog
                    handleCancel={handleCancel}
                    errorDailog={errorDailog}
                  />
                ) : (
                  " "
                )}
                <div className="customer-detail">
                  <Typography className="detail"><b>Venue ID :</b> {venueId}</Typography>
                  <Typography className="detail">{venueName}</Typography>
                </div>
                <TextField
                  margin="normal"
                  required
                  id="broadcastName"
                  label="Broadcast Name Input"
                  name="broadcastName"
                  autoFocus
                  // style={{marginLeft:-10}}
                  fullWidth
                  value={shedulerValues.templateName}
                  size="small"
                  color="error"
                  onChange={handleChange}
                />
                <div className="time-date-box">
                  <label htmlFor="broadcastStartTime">Broadcast Date</label>
                  <input type="text" id="broadcastStartTime" />
                </div>
                <DatePicker
                  className="datePicker"
                  dateFormat="MMM-dd-yyyy"
                  selected={startDate}
                  onChange={(value) => handleDateChange(value)}
                />
                <div className="time-box">
                  <TimePicker
                    value={dispatchTime}
                    onChange={handleValueChange}
                    showSecond={false}
                    allowEmpty
                    label="Broadcast Start Time"
                    placeholder="hh:mm"
                    className="timeInput"
                    id="broadcastStartTime"
                    format={format}
                    use12Hours
                    minuteStep={5}
                    sx={{ mt: 2, mb: 2 }}
                  />
                  <label htmlFor="broadcastStartTime">
                    Broadcast Start Time
                  </label>
                </div>
                <div className="time-box">
                  <TimePicker
                    value={dispatchEndTime}
                    onChange={onEndChange}
                    allowEmpty
                    label="Broadcast End Time"
                    showSecond={false}
                    defaultValue={now}
                    className="timeInput"
                    id="broadcastEndTime"
                    format={format}
                    use12Hours
                    minuteStep={5}
                    inputReadOnly
                    sx={{ mt: 2, mb: 2 }}
                    placeholder="hh:mm"
                  />
                  <label htmlFor="broadcastStartTime">Broadcast End Time</label>
                </div>
                <Button
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 1 }}
                  type="submit"
                >
                  SAVE
                </Button>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default EditDailyBroadcast;