import ActionTypes from "../constants/action-types";

const initialState = [];

export const templateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADD_TEMPLATE: console.log(state, type ,"state")
    console.log(payload, type ,"payload")
      const index = state.findIndex((a) => a.dayOfWeek === payload.dayOfWeek)
      if (index !== -1) {
        state[index] = payload
      }
      else {
        state = [...state, payload];
      }
      return state;
    case ActionTypes.RESET_TEMPLATE:
      state = [];
      return state;
    default:
      return state;
  }
};
