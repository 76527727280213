import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate as useHistory, useLocation } from "react-router-dom";
import CONSTANTS from "../../constants";
import Header from "../Shared/Header";
import Dailog from "../Shared/Dailog";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import "../Signin.scss";
const heading = "DASHBOARD";
let token;
let decoded;

const CustomerDashboard = (props) => {
  const { classes } = props;
  const [venuesList, setVenuesList] = useState([]);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [handlePopUp, setHandlePopUp] = useState(false);
  const history = useHistory();
  useEffect(() => {
    token = localStorage.getItem("access_token");
    if (token) decoded = jwt_decode(token);
    if (decoded) {
      getAllVenuesList();
      localStorage.setItem("userId",decoded.userId)
      console.log(decoded.userId);
    }
  }, []);

  const getAllVenuesList = async () => {
    try {
      const { data } = await axios.get(
        requests.getAllVenues + "/" + decoded?.userId
      );
      setVenuesList(data.data);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      } else {
        setHandlePopUp(true);
        if (error.response.statusText) {
          setPopUpMessage(error.response.statusText);
        } else {
          setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
        }
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userId")
    history("/");
  };

  const menuList = [
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };

  const handleVenue = (e, venue) => {
    history(`/venueDashboard?venueId=${venue.venueId}&userId=${decoded?.userId}`, {
      state: { detail: venue },
    });
  };
  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth="true"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                // className={classes.boxWidth}
                style={{
                  width: "100%",
                }}
                noValidate
                fullWidth="true"
                sx={{ mt: 3 }}
              >
                <div className="customer-detail" style={classes.boxWidth}>
                  <Typography className="detail">
                    {decoded?.organizationName}
                  </Typography>
                  <Typography className="detail">{decoded?.userId}</Typography>
                </div>

                <div className="venue-box-dashboard" style={classes.boxWidth}>
                  {venuesList?.map((venue, index) => {
                    return (
                      <div
                        className="venue-customer-dashboard"
                        key={index}
                        onClick={(e) => handleVenue(e, venue)}
                      >
                        <FontAwesomeIcon icon={faLocationDot} color="red" />
                        <Typography
                          className="venue-name"
                          style={{ marginLeft: 10 }}
                        >
                          {venue.venueName}
                        </Typography>
                      </div>
                    );
                  })}
                </div>

                <Typography
                  // variant="body2"
                  align="center"
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  // className={classes.fontInfo}
                >
                  Can't see desired venue? Click {""}
                  <a
                    target="blank"
                    href="https://www.gamecast.info/support"
                    className={classes.underLine}
                  >
                    here
                  </a>
                  <br />
                  to troubleshoot.
                </Typography>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CustomerDashboard;
