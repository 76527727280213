import React, { useState, useEffect } from "react";
import "../Signin.scss";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate as useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Header from "../Shared/Header";
import requests from "../Shared/requests";
import axios from "../Shared/axios";
import { getQueryString } from "../Shared/data";
import { Prompt } from "react-router";
import { useLocation } from "react-router-dom";
import Dailog from "../Shared/Dailog";
import Popup from "../Shared/PopUp";
import CONSTANTS from "../../constants";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

const heading = "NEW VENUE";
const errors = {};
let formEvent;
let formData;
let rtmpId;
let url;

const NewVenue = (props) => {
  const location = useLocation();
  const { classes } = props;
  const [preGeneratedVenueId, setPreGeneratedVenueId] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [venueValues, setVenueValues] = useState({
    venueName: "",
    venueLocation: "",
    timeZone: "EST",
    incomingRTMP: "",
    ipCameraUrl: "",
    username: "",
    password: "",
    ipCamera: false,
  });
  const [newVenueCreated, setNewVenueCreated] = useState(false);
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState();
  const [ipCameraToggle, setIpCameraToggle] = useState(false);
  const [ipCameraDataFilled, setIpCameraDataFilled] = useState(false);
  const history = useHistory();

  useEffect(() => {
    rtmpThing();
  }, []);

  const rtmpThing = async () => {
    rtmpId = Math.floor(
      10000000000000000000 + Math.random() * 90000000000000000000
    );
    url =
      requests.RtmpDomain +
      "/" +
      location?.state?.detail?.userId +
      "/" +
      rtmpId;
    setVenueValues((prevState) => ({ ...prevState, incomingRTMP: url }));

    try {
      const data = {
        userId: location?.state?.detail?.userId,
      };
      const request = await axios.get(
        requests.generateVenueId + "?" + getQueryString(data)
      );
      setPreGeneratedVenueId(request?.data?.data);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      setPopUpMessage(
        error.response.statusText || CONSTANTS.VARIABLES.NETWORK_ERROR
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVenueValues((prevState) => {
      const updatedValues = { ...prevState, [name]: value };
      const { ipCameraUrl, username, password } = updatedValues;
      updatedValues.ipCamera = Boolean(ipCameraUrl && username && password);

      return updatedValues;
    });
  };

  const handleToggleChange = () => {
    setIpCameraToggle((prevState) => {
      const newState = !prevState;
      setVenueValues((prevState) => ({
        ...prevState,
        ipCamera: newState,
      }));
      return newState;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    formEvent = event;
    setIsSubmit(true);

    const data = new FormData(event.currentTarget);
    data.set("venueId", preGeneratedVenueId);

    formData = {
      venueRtmpUrl: venueValues.incomingRTMP,
      location: venueValues.venueLocation,
      timeZone: venueValues.timeZone,
      userId: location?.state?.detail?.userId,
      venueId: data.get("venueId"),
      venueName: venueValues.venueName,
      ipCamera: false
    };
    if (venueValues.ipCamera) {
      formData = {
        ...formData,
        ipCamera: venueValues.ipCamera,
        ipCameraUrl: venueValues.ipCameraUrl,
        username: venueValues.username,
        password: venueValues.password,
      };
    }

    validate(event, venueValues);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      createVenue();
    } else {
      setIsSubmit(false);
    }
  }, [errors, isSubmit]);

  const createVenue = async () => {
    try {
      const response = await axios.post(requests.createVenue, formData);
      setNewVenueCreated(true);
      formEvent.target.reset();
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      setPopUpMessage(
        error.response.data.message || CONSTANTS.VARIABLES.NETWORK_ERROR
      );
    }
  };

  const validate = (event, values) => {
    if (!values.venueName) {
      errors.venueName = "Venue Name is required";
    } else {
      delete errors.venueName;
    }
    if (!values.venueLocation) {
      errors.venueLocation = "Venue Location is required!";
    } else {
      delete errors.venueLocation;
    }
    return errors;
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };

  const handleClosePopup = () => {
    setHandlePopUp(false);
  };

  const handleCloseDialog = () => {
    const hasData =
      venueValues.ipCameraUrl || venueValues.username || venueValues.password;
    setIpCameraDataFilled(hasData);
    setIpCameraToggle(false);
  };
  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            fullWidth
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Header heading={heading} formErrors={errors} />
            {handlePopUp && (
              <Popup
                handlePopUp={handlePopUp}
                message={popUpMessage}
                handleClosePopup={handleClosePopup}
              />
            )}
            <Box
              component="form"
              sx={{ mt: 1, height: "auto", ...classes.boxWidth }}
              noValidate
              fullWidth
              onSubmit={handleSubmit}
            >
              <Dailog newVenueCreated={newVenueCreated} />
              <TextField
                margin="normal"
                required
                id="venueId"
                label="Venue ID"
                name="venueId"
                autoFocus
                fullWidth
                size="small"
                color="error"
                value={preGeneratedVenueId}
                disabled
              />
              <TextField
                margin="normal"
                required
                id="venueName"
                label="Venue Name"
                name="venueName"
                fullWidth
                size="small"
                value={venueValues.venueName}
                onChange={handleChange}
                color="error"
              />
              <TextField
                margin="normal"
                required
                id="venueLocation"
                label="Venue Location"
                name="venueLocation"
                fullWidth
                size="small"
                value={venueValues.venueLocation}
                onChange={handleChange}
                color="error"
              />
              <div className="timeZoneBox">
                <i className="fas fa-caret-down select-icon"></i>
                <select
                  name="timeZone"
                  id="timeZone"
                  value={venueValues.timeZone}
                  onChange={handleChange}
                  aria-label="Select Timezone"
                >
                  {["EST", "CST", "MST", "PST", "HST"].map((zone) => (
                    <option key={zone} value={zone}>
                      {zone} Standard Time
                    </option>
                  ))}
                </select>
              </div>
              <TextField
                margin="normal"
                required
                id="incomingRTMP"
                label="Incoming RTMP"
                name="incomingRTMP"
                fullWidth
                size="small"
                value={venueValues.incomingRTMP}
                onChange={handleChange}
              />
              {/* <div style={{ marginTop: "10px" }}>
                <div
                  className="activ-inactive"
                  style={{
                    gap: "100px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    Would you like to <br /> add an IP Camera?
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="active"
                      id="active"
                      onClick={handleToggleChange}
                      role="switch"
                      checked={ipCameraToggle || ipCameraDataFilled}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                {ipCameraToggle && (
                  <div style={{ marginTop: "20px" }}>
                    <TextField
                      margin="normal"
                      required
                      id="ipCamera"
                      label="IP Camera URL"
                      helperText="e.g. <IP of Camera>:80/onvif/device_service"
                      name="ipCameraUrl"
                      fullWidth
                      size="small"
                      value={venueValues.ipCameraUrl}
                      onChange={handleChange}
                      FormHelperTextProps={{
                        sx: { marginLeft: 0 },
                      }}
                    />
                    <TextField
                      margin="normal"
                      required
                      id="username"
                      label="IP Camera Username"
                      name="username"
                      fullWidth
                      size="small"
                      value={venueValues.username}
                      onChange={handleChange}
                    />
                    <TextField
                      margin="normal"
                      required
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      fullWidth
                      size="small"
                      value={venueValues.password}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </div> */}
              <div style={{ marginTop: "10px" }}>
                <div
                  className="activ-inactive"
                  style={{
                    gap: "100px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    Would you like to <br /> add an IP Camera?
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="active"
                      id="active"
                      onClick={handleToggleChange}
                      role="switch"
                      checked={ipCameraToggle || ipCameraDataFilled}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <Dialog
                  open={ipCameraToggle}
                  onClose={handleCloseDialog}
                  maxWidth="xs"
                  fullWidth
                >
                  <DialogTitle>
                    Add IP Camera
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseDialog}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      ×
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      margin="normal"
                      required
                      id="ipCamera"
                      label="IP Camera URL"
                      helperText="e.g. <IP of Camera / Domain>:80/onvif/device_service"
                      name="ipCameraUrl"
                      fullWidth
                      size="small"
                      value={venueValues.ipCameraUrl}
                      onChange={handleChange}
                      FormHelperTextProps={{
                        sx: { marginLeft: 0 },
                      }}
                    />
                    <TextField
                      margin="normal"
                      required
                      id="username"
                      label="IP Camera Username"
                      name="username"
                      fullWidth
                      size="small"
                      value={venueValues.username}
                      onChange={handleChange}
                    />
                    <TextField
                      margin="normal"
                      required
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      fullWidth
                      size="small"
                      value={venueValues.password}
                      onChange={handleChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      type="button"
                      variant="contained"
                      fullWidth
                      onClick={handleCloseDialog}
                      sx={{
                        ml: 2,
                        mr: 2,
                        backgroundColor: "black",
                        "&:hover": {
                          backgroundColor: "black",
                        },
                      }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <Button
                type="submit"
                className="sign-in-button"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </div>
      </Container>
    </div>
  );
};

export default NewVenue;
