import { Box, Button, Container, CssBaseline, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../Shared/Header'
import { useLocation, useNavigate } from 'react-router'
import TimePicker from 'rc-time-picker'
import moment from "moment";
import axios from '../Shared/axios'
import requests from '../Shared/requests'
import Dailog from '../Shared/Dailog'
import MESSAGES from '../../constants/messages'
import AlertDialogSlide from '../Shared/Dailog'
const DaySetting = ({ classes }) => {
    const [dispatchTime, setDispatchTime] = useState();
    const [activeStatus, setActiveStatus] = useState(false);
    const [dispatchEndTime, setDispatchEndTime] = useState();
    const [handlePopUp, setHandlePopUp] = useState(false);
    const [popUpMessage, setPopUpMessage] = useState("");
    const [errorTime, setErrorTime] = useState(false);
    const history = useNavigate();
    const location = useLocation();
    const format = "hh:mm A";
    let now;

    const validateTime = () => {
        if ((+dispatchEndTime <= +dispatchTime) || !dispatchEndTime || !dispatchTime) {
            setErrorTime("END TIME NEEDS TO BE LATER THAN START TIME")
            return;
        }
        handleSave();
    }

    const handleSave = async () => {
        const dto = {
            endTime: {
                hour: moment(dispatchEndTime).format("HH"),
                minute: moment(dispatchEndTime).format("mm")
            },
            id: location?.state?.day?.id,
            startTime: {
                hour: moment(dispatchTime).format("HH"),
                minute: moment(dispatchTime).format("mm")
            },
            status: activeStatus,
            userId: location?.state?.detail?.userId,
            weekday: location?.state?.day?.weekday
        }
        try {
            const data = await axios.put(requests.weekdaySetting, dto)
            setHandlePopUp(true);
            setPopUpMessage(MESSAGES.SYSTEM_SCHEDULE_SUCCESSFULLY)
        } catch (error) {
            if (error.response.status === 403) {
                handleLogout();
            } else {
                setErrorTime(error.message)
            }

            console.log(error.message)
        }
    }

    const closeErrorDialog = () => {
        setErrorTime(false)
    }
    const handleActive = () => {
        setActiveStatus((status) => !status)
    }


    const getMomentTime = (time) => {
        if (time) {
            const today = moment().format("YYYY-MM-DD");
            const momentInTime = moment(`${today} ${time}`);
            return momentInTime
        } else {
            return null
        }
    }

    useEffect(() => {
        setDispatchTime(getMomentTime(location?.state?.day?.startTime))
        setDispatchEndTime(getMomentTime(location?.state?.day?.endTime))
        setActiveStatus(location?.state?.day?.streamStatus)
    }, [location.state.day])


    const handleClosePopup = () => {
        setHandlePopUp(false);
        history(-1)
    };

    const handleValueChange = (value) => {
        setDispatchTime(value);
    };

    const onEndChange = (value) => {
        setDispatchEndTime(value);
    };

    const handleLogout = () => {
        localStorage.removeItem("access_token");
        history("/admin/");
    };
    const customerListHandler = () => {
        history("/admin/customerlist");
    };
    const newCustomer = () => {
        history("/admin/newcustomer");
    };
    const handleEditCustomer = () => {
        history("/admin/editCustomer", {
            state: { detail: location?.state?.detail },
        });
    };
    const menuList = [
        {
            menuItem: "New Customer",
            handle: newCustomer,
        },
        {
            menuItem: "Customer List",
            handle: customerListHandler,
        },
        {
            menuItem: "Edit Customer",
            handle: handleEditCustomer,
        },
        {
            menuItem: "Logout",
            handle: handleLogout,
        },
    ];
    return (
        <div className="signin-body">
            <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
                <CssBaseline />
                <div className="main-box">
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Header menuList={menuList} heading={'DAILY SCHEDULE'} />
                        {handlePopUp ? (
                            <Dailog
                                handleCancel={handleClosePopup}
                                errorDailog={popUpMessage}
                            />
                        ) : (
                            " "
                        )}
                        {errorTime ? (
                            <AlertDialogSlide
                                handleCancel={closeErrorDialog}
                                errorTime={errorTime}
                            />
                        ) : (
                            " "
                        )}
                        <Box style={classes.boxWidth}>
                            <Typography variant='h6' align='center' sx={{ color: 'red', my: 3 }}>
                                {location?.state?.day?.weekday}
                            </Typography>
                            <Box sx={{ my: 3 }}>
                                <Typography variant="h6" align='center'>SYSTEM ON/OFF</Typography>
                                <div className="activ-inactive" >
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={activeStatus}
                                            name="active"
                                            id="active"
                                            onChange={(e) => handleActive(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <Typography variant="body2">
                                        {activeStatus ? "on" : "off"}
                                    </Typography>
                                </div>
                            </Box>
                            <div className="time-box">
                                <TimePicker
                                    value={dispatchTime}
                                    onChange={handleValueChange}
                                    showSecond={false}
                                    allowEmpty
                                    minuteStep={5}
                                    label="Broadcast Start Time"
                                    placeholder="hh:mm"
                                    className="timeInput"
                                    id="broadcastStartTime"
                                    format={format}
                                    use12Hours
                                    sx={{ mt: 2, mb: 2 }}
                                />
                                <label htmlFor="broadcastStartTime">
                                    System Start Time
                                </label>
                            </div>
                            <div className="time-box">
                                <TimePicker
                                    value={dispatchEndTime}
                                    minuteStep={5}
                                    onChange={onEndChange}
                                    allowEmpty
                                    label="Broadcast End Time"
                                    showSecond={false}
                                    defaultValue={now}
                                    className="timeInput"
                                    id="broadcastEndTime"
                                    format={format}
                                    use12Hours
                                    inputReadOnly
                                    sx={{ mt: 2, mb: 2 }}
                                    placeholder="hh:mm"
                                />
                                <label htmlFor="broadcastStartTime">System End Time</label>
                            </div>
                            <Button
                                className="sign-in-button"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2, mb: 4 }}
                                onClick={validateTime}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </div>
            </Container>
        </div>
    )
}

export default DaySetting