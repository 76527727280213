import React, { useState, useEffect, useRef } from "react";
import "../Signin.scss";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate as useHistory } from "react-router-dom";
// import { makeStyles } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Header from "../Shared/Header";
import requests from "../Shared/requests";
import axios from "../Shared/axios";
import Dailog from "../Shared/Dailog";
import { useLocation } from "react-router";
import { getQueryString } from "../Shared/data";
import CONSTANTS from "../../constants";
import Popup from "../Shared/PopUp";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
const heading = "EDIT VENUE";
let rtmpId;
let renerateRtmpId;

const NewVenue = (props) => {
  const { classes } = props;
  const history = useHistory();
  const location = useLocation();
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [playerSrc, setPlayerSrc] = useState("");
  const [deleteVenue, setDeleteVenue] = useState(false);
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [venueData, setVenueData] = useState({
    incomingRTMP: location?.state?.detail?.venueRtmpUrl,
    venueLocation: location?.state?.detail?.location,
    timeZone: location?.state?.detail?.timeZone,
    userId: location?.state?.detail?.userId,
    venueId: location?.state?.detail?.venueId,
    venueName: location?.state?.detail?.venueName,
    ipCameraUrl: location?.state?.detail?.ipCameraUrl,
    username: location?.state?.detail?.username,
    password: location?.state?.detail?.password,
  });
  const [reGenStatus, setRegenStatus] = useState(false);
  useEffect(() => {
    let arr = location?.state?.detail?.venueRtmpUrl.split("/");
    if (arr && arr.length > 0) {
      rtmpId = arr[arr.length - 1];
      setPlayerSrc(
        requests.playerSRC +
        location?.state?.detail?.userId +
        requests.configURl +
        rtmpId
      );
    }
  }, [location]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVenueData((preValue) => {
      return { ...preValue, [name]: value };
    });
  };
  const [venueValues, setVenueValues] = useState({
    venueName: "",
    venueLocation: "",
    timeZone: "EST",
    incomingRTMP: "",
    ipCameraUrl: "",
    username: "",
    password: "",
    ipCameraEnable: "",
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      venueRtmpUrl: venueData.incomingRTMP,
      location: data.get("venueLocation"),
      userId: venueData.userId,
      timeZone: venueData.timeZone,
      venueName: data.get("venueName"),
      ipCameraUrl: venueData.ipCameraUrl,
      username: venueData.username,
      password: venueData.password,
      //  ipCameraEnable: ipCameraEnabled,
      ipCamera: (venueData.ipCameraUrl && venueData.username && venueData.password) ? true : false
    };

    try {
      const request = await axios.put(
        `${requests.createVenue}/${venueData.venueId}`,
        formData
      );
      setSuccessMessage(CONSTANTS.VARIABLES.VENUE_EDITED_SUCCESS);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      if (error.response.statusText) {
        setPopUpMessage(error.response.statusText);
      } else {
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
      console.log(error);
    }
  };

  const handleCloseSuccess = () => {
    setSuccessMessage("")
    if (!reGenStatus) {
      history(-1);
    }
    setRegenStatus(false)
  }

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };
  const customerListHandler = () => {
    history("/admin/customerlist");
  };
  const newCustomer = () => {
    history("/newcustomer");
  };
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);

  const menuList = [
    {
      menuItem: "New Customer",
      handle: newCustomer,
    },
    {
      menuItem: "Customer List",
      handle: customerListHandler,
    },
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const handleCancel = () => {
    setTimeout(() => {
      setDeleteVenue(false);
    }, 2000);
  };
  const handleClosePopup = () => {
    setHandlePopUp(false);
  };
  const regGenerateRtmp = async () => {
    try {
      let id = Math.floor(
        10000000000000000000 + Math.random() * 90000000000000000000
      );
      //let url = `rtmp://admin.gamecast.info/${location?.state?.detail?.userId}/${id}`;
      let url =
        requests.RtmpDomain + "/" + location?.state?.detail?.userId + "/" + id;
      setVenueData((preValue) => {
        return { ...preValue, incomingRTMP: url };
      });
      const reGendata = {
        streamId: id,
        userId: location?.state?.detail?.userId,
        venueId: location?.state?.detail?.venueId,
      };
      const data = axios.post(
        requests.createRtmpUrl,
        getQueryString(reGendata)
      );
      setPlayerSrc(
        requests.playerSRC +
        location?.state?.detail?.userId +
        requests.configURl +
        id
      );
      setRegenStatus(true);
      setSuccessMessage(CONSTANTS.VARIABLES.RTMP_REGENERATED);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      setHandlePopUp(true);
      if (error.response.statusText) {
        setPopUpMessage(error.response.statusText);
      } else {
        setPopUpMessage(CONSTANTS.VARIABLES.NETWORK_ERROR);
      }
      console.log(error);
    }
  };
  const handleClose = () => {
    setIpCameraToggle(false);
  };
  const [ipCameraToggle, setIpCameraToggle] = useState(false);
  const ipCameraEnabled = location?.state?.detail?.ipCameraEnable === true;
  const handleToggleChange = () => {
    setIpCameraToggle((prevState) => {
      const newState = !prevState;
      setVenueValues((preValue) => ({
        ...preValue,
        ipCameraEnable: newState,
      }));
      return newState;
    });
  };
  const [ipCameraUrl, setIpCameraUrl] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSave = () => {
    console.log("ipCameraUrl:", ipCameraUrl);
    console.log("username:", username);
    console.log("password:", password);

    setVenueData((prev) => {
      const updatedData = {
        ...prev,
        VenueipCameraUrl: ipCameraUrl || prev.VenueipCameraUrl,
        venueUsername: username || prev.venueUsername,
        venuePassword: password || prev.venuePassword,
      };
      console.log("Updated State:", updatedData);
      return updatedData;
    });

    setIpCameraToggle(false);
    setInfoModalOpen(false);
    console.log("Saved Data:", {
      ipCameraUrl,
      username,
      password,
    });
  };

  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              {successMessage ? (
                <Dailog
                  handleCancel={handleCloseSuccess}
                  errorDailog={successMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                style={classes.boxWidth}
                noValidate
                fullWidth
                onSubmit={handleSubmit}
                sx={{ mt: 1, minHeight: "26rem", height: "auto" }}
              >
                <TextField
                  margin="normal"
                  required
                  id="venueId"
                  label="Venue ID"
                  name="venueId"
                  fullWidth
                  value={venueData.venueId}
                  style={classes.inputFieldBold}
                  size="small"
                  color="error"
                  disabled={true}
                />
                <TextField
                  margin="normal"
                  required
                  id="venueName"
                  label="Venue Name"
                  name="venueName"
                  fullWidth
                  size="small"
                  value={venueData.venueName}
                  style={classes.inputFieldBold}
                  color="error"
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  margin="normal"
                  required
                  id="venueLocation"
                  label="Venue Location"
                  name="venueLocation"
                  fullWidth
                  size="small"
                  color="error"
                  value={venueData.venueLocation}
                  onChange={(e) => handleChange(e)}
                />
                <div className="timeZoneBox">
                  <i className="fas fa-caret-down select-icon"></i>
                  <select
                    name="timeZone"
                    id="timeZone"
                    value={venueData.timeZone}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="EST">Eastern Standard Time</option>
                    <option value="CST">Central Standard Time</option>
                    <option value="MST">Mountain Standard Time</option>
                    <option value="PST">Pacific Standard Time</option>
                    <option value="HST">Hawaii Standard Time</option>
                  </select>
                </div>
                <TextField
                  margin="normal"
                  required
                  id="incomingRTMP"
                  label="Incoming RTMP"
                  name="incomingRTMP"
                  fullWidth
                  size="small"
                  color="error"
                  sx={{ mb: 3 }}
                  value={venueData.incomingRTMP}
                  onChange={(e) => handleChange(e)}
                />

                <Dailog
                  regGenerateRtmp={regGenerateRtmp}
                  title={"Re-generate RTMP Link"}
                  editVenue={true}
                  handleCancel={handleCancel}
                />
                {/* <div style={{ marginTop: "10px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0,
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        flexWrap: "nowrap",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 700,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Would you like to
                        <br /> add an IP Camera?
                      </Typography>
                    </Box>
                    <div
                      className="activ-inactive"
                      style={{
                        paddingLeft: "130px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="active"
                          id="active"
                          role="switch"
                          tabIndex={0}
                          checked={ipCameraEnabled}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </Box>
                  {ipCameraEnabled && (
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        margin="normal"
                        required
                        id="ipCamera"
                        label="IP Camera URL"
                        helperText="e.g. <IP of Camera>:80/onvif/device_service"
                        name="ipCamera"
                        fullWidth
                        size="small"
                        color="primary"
                        value={venueData.VenueipCameraUrl}
                        onChange={(e) =>
                          setVenueData((prev) => ({
                            ...prev,
                            VenueipCameraUrl: e.target.value,
                          }))
                        }
                        FormHelperTextProps={{
                          sx: { marginLeft: 0 },
                        }}
                      />
                      <TextField
                        margin="normal"
                        required
                        id="username"
                        label="IP Camera Username"
                        name="username"
                        fullWidth
                        size="small"
                        color="primary"
                        value={venueData.venueUsername}
                        onChange={(e) =>
                          setVenueData((prev) => ({
                            ...prev,
                            venueUsername: e.target.value,
                          }))
                        }
                      />
                      <TextField
                        margin="normal"
                        required
                        id="password"
                        label="Password"
                        name="password"
                        type="text"
                        fullWidth
                        size="small"
                        color="primary"
                        value={venueData.venuePassword}
                        onChange={(e) =>
                          setVenueData((prev) => ({
                            ...prev,
                            venuePassword: e.target.value,
                          }))
                        }
                      />
                    </Box>
                  )}
                </div> */}

                <div style={{ marginTop: "10px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0,
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        flexWrap: "nowrap",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 700,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Would you like to
                        <br /> add an IP Camera?
                      </Typography>
                    </Box>
                    <div
                      className="activ-inactive"
                      style={{
                        paddingLeft: "80px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="active"
                          id="active"
                          role="switch"
                          tabIndex={0}
                          onClick={handleToggleChange}
                          checked={
                            location?.state?.detail?.ipCameraEnable === true || venueValues.ipCameraEnable
                          }
                          readOnly
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <IconButton
                      style={{ color: "black" }}
                      size="medium"
                      onClick={() => setInfoModalOpen(true)}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </IconButton>


                    <Dialog
                      open={infoModalOpen}
                      onClose={() => setInfoModalOpen(false)}
                      maxWidth="xs"
                      fullWidth
                    >
                      <DialogTitle>
                        Add IP Camera
                        <IconButton
                          aria-label="close"
                          onClick={() => setInfoModalOpen(false)}
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          ×
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                        <TextField
                          margin="normal"
                          required
                          id="ipCamera"
                          label="IP Camera URL"
                          helperText="e.g. <IP of Camera / Domain>:80/onvif/device_service"
                          name="ipCamera"
                          fullWidth
                          size="small"
                          color="primary"
                          value={venueData.ipCameraUrl}
                          onChange={(e) =>
                            setVenueData((prev) => ({
                              ...prev,
                              ipCameraUrl: e.target.value,
                            }))
                          }
                          FormHelperTextProps={{
                            sx: { marginLeft: 0 },
                          }}
                        />
                        <TextField
                          margin="normal"
                          required
                          id="username"
                          label="IP Camera Username"
                          name="username"
                          fullWidth
                          size="small"
                          color="primary"
                          value={venueData.username}
                          onChange={(e) =>
                            setVenueData((prev) => ({
                              ...prev,
                              username: e.target.value,
                            }))
                          }
                        />
                        <TextField
                          margin="normal"
                          required
                          id="password"
                          label="Password"
                          name="password"
                          type="text"
                          fullWidth
                          size="small"
                          color="primary"
                          value={venueData.password}
                          onChange={(e) =>
                            setVenueData((prev) => ({
                              ...prev,
                              password: e.target.value,
                            }))
                          }
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          type="button"
                          variant="contained"
                          fullWidth
                          sx={{
                            ml: 2,
                            mr: 2,
                            backgroundColor: "black",
                            "&:hover": {
                              backgroundColor: "black",
                            },
                          }}
                          onClick={handleSave}
                        >
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </div>

                <div className="rtmp-preview-box">
                  <iframe
                    title="liveStream"
                    width="100%"
                    height="100%"
                    src={playerSrc}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
                <Button
                  type="submit"
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NewVenue;
