import ActionTypes from "../constants/action-types"

export const setTemplate = (data) => {
  return {
    type: ActionTypes.ADD_TEMPLATE,
    payload: data
  }
}
export const resetTemplate = (data) => {
  return {
    type: ActionTypes.RESET_TEMPLATE,
    payload: data
  }
}
export default { setTemplate, resetTemplate }