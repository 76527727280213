import {
  faAngleDown,
  faAngleUp,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TextField,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { useState, useEffect } from "react";

const Channel = ({ value, chanelNumber, updateChannel, saveButtonToogle }) => {
  const [customSocial, setCustomSocial] = useState(false);
  const [channelValue, setChannelValue] = useState(value);
  const [activeStatus, setActiveStatus] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const socialArr = ["Youtube", "Twitch", "Facebook"];

  useEffect(() => {
    setActiveStatus(value.channelEnable);
    if (value.channelName && !socialArr.includes(value.channelName)) {
      setCustomSocial(true);
    }
  }, [value]);

  useEffect(() => {
    if (
      channelValue.channelName &&
      !socialArr.includes(channelValue.channelName)
    ) {
      setCustomSocial(true);
    } else {
      setCustomSocial(false);
    }
  }, [channelValue]);
  useEffect(() => {
    updateChannel({ ...channelValue, channelEnable: activeStatus });
  }, [channelValue, activeStatus, updateChannel]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChannelValue((prevValue) => ({ ...prevValue, [name]: value }));
  };

  // const handleCustomNameChange = (e) => {
  //   const customNameValue = e.target.value;
  //   setChannelValue((prevValue) => ({ ...prevValue, customName: customNameValue }));
  // };

  return (
    <Box sx={{ border: 1, borderColor: "#b7b7b7", p: 1, mb: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            CHANNEL
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {chanelNumber}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <div
            className="activ-inactive"
            style={{
              paddingLeft: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <label className="switch">
              <input
                type="checkbox"
                name="active"
                id="active"
                role="switch"
                checked={activeStatus}
                onChange={() => setActiveStatus(!activeStatus)}
                disabled={saveButtonToogle}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                  }
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <Typography variant="body2">{activeStatus ? "ON" : "OFF"}</Typography>
          <IconButton
            style={{ color: "black" }}
            size="small"
            onClick={() => setShowDetails(!showDetails)}
            disabled={!activeStatus}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </IconButton>
          <IconButton
            style={{ color: "black" }}
            size="small"
            onClick={() => setShowDetails(!showDetails)}
            disabled={!activeStatus}
          >
            <FontAwesomeIcon icon={showDetails ? faAngleUp : faAngleDown} />
          </IconButton>
        </Box>
      </Box>
      {activeStatus && showDetails && (
        <Box>
          <div className="timeZoneBox">
            <i className="fas fa-caret-down select-icon"></i>
            <select
              name="channelName"
              value={
                socialArr.includes(channelValue?.channelName)
                  ? channelValue?.channelName
                  : channelValue?.channelName?.length
                  ? "Custom"
                  : ""
              }
              id="social"
              onChange={handleChange}
              disabled={saveButtonToogle}
            >
              <option value="">Select Channel</option>
              <option value="Twitch">Twitch</option>
              <option value="Facebook">Gamechanger</option>
              <option value="Youtube">Youtube</option>
              <option value="Custom">Custom</option>
            </select>
          </div>
          {/* {channelValue.channelName === "Custom" && (
            <TextField
              margin="none"
              required
              id="customName"
              label="Enter custom name"
              name="customName"
              fullWidth
              size="small"
              color="error"
              value={channelValue.customName }
              onChange={handleCustomNameChange}
              sx={{ mt: -1, mb: 3 }}
              disabled={saveButtonToogle}
            />
          )} */}
          <TextField
            margin="none"
            required
            id="rtmpAddress"
            label="Enter destination RTMP address"
            name="socialMediaRtmpUrl"
            fullWidth
            size="small"
            color="error"
            value={channelValue.socialMediaRtmpUrl}
            onChange={handleChange}
            sx={{ mt: -1, mb: 0 }}
            disabled={saveButtonToogle}
            title={channelValue.socialMediaRtmpUrl}
          />
        </Box>
      )}
    </Box>
  );
};

export default Channel;
