import {
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Shared/Header";
import { useLocation, useNavigate } from "react-router";
import TimePicker from "rc-time-picker";
import moment from "moment";
import axios from "../Shared/axios";
import requests from "../Shared/requests";

const EditDailyTemplate = ({ classes }) => {
  const [dispatchTime, setDispatchTime] = useState();
  const [dispatchEndTime, setDispatchEndTime] = useState();
  const [venueName, setVenueName] = useState("");
  const [confirmationPopup, setConfirmationPopup] = useState(false); 
  const [errorTime, setErrorTime] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const format = "hh:mm A";
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId") || "defaultVenueId";
  const templateId = searchParams.get("templateId") || 0;

  const validateTime = () => {
    if (
      !dispatchEndTime ||
      !dispatchTime ||
      moment(dispatchEndTime).isBefore(dispatchTime)
    ) {
      setErrorTime("END TIME NEEDS TO BE LATER THAN START TIME");
      return;
    }
    handleSave();
  };

  const handleSave = async () => {
    const dto = {
      id: templateId,
      endTime: {
        hour: moment(dispatchEndTime).format("HH"),
        minute: moment(dispatchEndTime).format("mm"),
      },
      startTime: {
        hour: moment(dispatchTime).format("HH"),
        minute: moment(dispatchTime).format("mm"),
      },
      type: "daily",
      venueId: venueId,
    };

    try {
      const response = await axios.put(requests.updateScheduleTemplate, dto);
      if (response.data.success) {
        setConfirmationPopup(true); // Show success popup
      } else {
        setErrorTime("FAILED TO UPDATE THE SCHEDULE TEMPLATE");
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        handleLogout();
      } else {
        setErrorTime(error.message);
      }
      console.log(error.message);
    }
  };

  const closeErrorDialog = () => {
    setErrorTime(false);
  };

  const handleCloseConfirmation = () => {
    setConfirmationPopup(false);
    history(-1); // Go back to the previous page
  };

  const handleValueChange = (value) => {
    setDispatchTime(value);
  };

  const onEndChange = (value) => {
    setDispatchEndTime(value);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };

  useEffect(() => {
    if (!templateId) return;
  
    axios
      .get(requests.getTemplate + `?venueId=${venueId}&templateId=${templateId}`)
      .then((response) => {
        if (response.data.success) {
          const dailyTemplate = response.data.data.daily.find(
            (template) => template.id === parseInt(templateId)
          );
  
          if (dailyTemplate) {
            const scheduleDetails = dailyTemplate.scheduleTemplateDetailDTOS[0];
  
            if (scheduleDetails && scheduleDetails.startTimeAndEndTimeDTOS.length > 0) {
              const timePair = scheduleDetails.startTimeAndEndTimeDTOS[0];
  
              if (timePair) {
                const startTime = moment()
                  .set("hour", timePair.startTime.hour)
                  .set("minute", timePair.startTime.minute);
                const endTime = moment()
                  .set("hour", timePair.endTime.hour)
                  .set("minute", timePair.endTime.minute);
  
                setDispatchTime(startTime);
                setDispatchEndTime(endTime);
              }
            }
  
            setVenueName(dailyTemplate.venueName);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching daily template data:", error);
      });
  }, [venueId, templateId]);  

  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Header menuList={[]} heading={"BROADCAST"} />
            {errorTime && (
              <Dialog
                open={!!errorTime}
                onClose={closeErrorDialog}
                PaperProps={{
                  sx: {
                    borderRadius: "8px",
                    // padding: "10px",
                    width: "500px",
                    textAlign: "center",
                  },
                }}
              >
                <DialogContent
                  sx={{
                    textAlign: "center",
                    padding: "2rem",
                  }}
                >
                  <DialogContentText></DialogContentText>
                  <Typography variant="h4" sx={{ mb: 0 }}>
                    <b>{errorTime}</b>
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "red",
                      color: "white",
                      width: "100%",
                      padding: "10px",
                      "&:hover": {
                        backgroundColor: "red",
                      },
                    }}
                    onClick={closeErrorDialog}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            <Box style={classes.boxWidth} sx={{ mt: 2 }}>
              <div className="customer-detail">
                <Typography className="detail">
                  <b>Venue ID :</b> {venueId}
                </Typography>
                <Typography className="detail">{venueName}</Typography>
              </div>
              <Typography
                variant="h6"
                align="center"
                sx={{ color: "black", my: 1 }}
              >
                Daily Template
              </Typography>
              <div className="time-box">
                <TimePicker
                  value={dispatchTime}
                  onChange={handleValueChange}
                  showSecond={false}
                  allowEmpty
                  minuteStep={5}
                  label="Broadcast Start Time"
                  placeholder="hh:mm"
                  className="timeInput"
                  id="broadcastStartTime"
                  format={format}
                  use12Hours
                  sx={{ mt: 2, mb: 2 }}
                />
                <label htmlFor="broadcastStartTime">Broadcast Start Time</label>
              </div>
              <div className="time-box">
                <TimePicker
                  value={dispatchEndTime}
                  minuteStep={5}
                  onChange={onEndChange}
                  allowEmpty
                  label="Broadcast End Time"
                  showSecond={false}
                  className="timeInput"
                  id="broadcastEndTime"
                  format={format}
                  use12Hours
                  inputReadOnly
                  sx={{ mt: 2, mb: 2 }}
                  placeholder="hh:mm"
                />
                <label htmlFor="broadcastEndTime">Broadcast End Time</label>
              </div>
              <Button
                className="sign-in-button"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 4 }}
                onClick={validateTime}
              >
                Save
              </Button>
            </Box>
          </Box>
        </div>
        <Dialog
          PaperProps={{
            sx: {
              borderRadius: "8px",
              // padding: "10px",
              width: "500px",
              textAlign: "center",
            },
          }}
          open={confirmationPopup}
          onClose={handleCloseConfirmation}
        >
          <DialogContent
            sx={{
              textAlign: "center",
              padding: "30px",
            }}
          >
            <Typography variant="h4" sx={{ mb: 2 }}>
              <b>
               DAILY TEMPLATE UPDATED<br/> SUCCESSFULLY
              </b>
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                color: "white",
                width: "100%",
                padding: "10px",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
              onClick={handleCloseConfirmation}
            >
              OK
            </Button>
          </DialogContent>
        </Dialog>
      </Container>
    </div>
  );
};

export default EditDailyTemplate;
