import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Shared/Header";
import { useLocation, useNavigate } from "react-router";
import TimePicker from "rc-time-picker";
import moment from "moment";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import Dailog from "../Shared/Dailog";
import AlertDialogSlide from "../Shared/Dailog";
import { useDispatch, useSelector } from "react-redux";
import { setTemplate } from "../../redux/actions";

const WeeklySet = ({ classes }) => {
  const [dispatchTime, setDispatchTime] = useState();
  const [dispatchEndTime, setDispatchEndTime] = useState();
  const [dailyTemplates, setDailyTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [errorTime, setErrorTime] = useState(false);
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const weekTemplateData = useSelector((state) => state.template);
  const history = useNavigate();
  const location = useLocation();
  const format = "hh:mm A";
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId") || "defaultVenueId";
  const [additionalFields, setAdditionalFields] = useState([]);
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          console.error("No access token found in localStorage");
          return;
        }
        const response = await axios.get(
          requests.getTemplate + "?" + `venueId=${venueId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          const data = response.data.data;
          setDailyTemplates(data.daily || []);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 403) {
            history("/");
          } else {
            console.error("Error response:", error.response.data);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
    };
    fetchTemplates();
  }, [venueId, history]);

  const handleTemplateChange = (event) => {
    const selectedTemplateId = event.target.value;
    setSelectedTemplate(selectedTemplateId);
  
    const template = dailyTemplates.find((t) => t.id === selectedTemplateId);
    if (template?.scheduleTemplateDetailDTOS?.length > 0) {
      const timeData =
        template.scheduleTemplateDetailDTOS[0]?.startTimeAndEndTimeDTOS?.[0];
  
      if (timeData?.startTime && timeData?.endTime) {
        setDispatchTime(
          moment()
            .hour(timeData.startTime.hour)
            .minute(timeData.startTime.minute)
        );
        setDispatchEndTime(
          moment().hour(timeData.endTime.hour).minute(timeData.endTime.minute)
        );
      } else {
        setDispatchTime(null);
        setDispatchEndTime(null);
      }
    } else {
      setDispatchTime(null);
      setDispatchEndTime(null);
    }
  };
  
  const handleAddMore = () => {
    setAdditionalFields((prevFields) => {
      const newField = {
        template: "",
        startTime: null,
        endTime: null,
      };
      return [...prevFields, newField];
    });
  };
  const handleTemplateChangeAdditional = (index, event) => {
    const selectedTemplateId = event.target.value;
    const template = dailyTemplates.find((t) => t.id === selectedTemplateId);
  
    if (template?.scheduleTemplateDetailDTOS?.length > 0) {
      const timePairs =
        template.scheduleTemplateDetailDTOS[0]?.startTimeAndEndTimeDTOS || [];
  
      if (timePairs.length > 0) {
        const selectedStartTime = moment()
          .hour(timePairs[0].startTime.hour)
          .minute(timePairs[0].startTime.minute);
        const selectedEndTime = moment()
          .hour(timePairs[0].endTime.hour)
          .minute(timePairs[0].endTime.minute);
  
        setAdditionalFields((prevFields) =>
          prevFields.map((field, i) =>
            i === index
              ? {
                  ...field,
                  template: selectedTemplateId,
                  startTime: selectedStartTime,
                  endTime: selectedEndTime,
                }
              : field
          )
        );
      }
    }
  };  

  const handleStartTimeChangeAdditional = (index, value) => {
    setAdditionalFields((prevFields) => {
      const newFields = [...prevFields];
      newFields[index].startTime = value;
      return newFields;
    });
  };
  const handleEndTimeChangeAdditional = (index, value) => {
    setAdditionalFields((prevFields) => {
      const newFields = [...prevFields];
      newFields[index].endTime = value;
      return newFields;
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const existingTimes = new Set();
    let duplicateFound = false;
    let missingTimeFound = false;

    const generateId = (prefix, index) => `${prefix}-${Date.now()}-${index}`;
    const formatTime = (time) =>
      time ? { hour: time.hour(), minute: time.minute() } : null;

    const additionalTimeEntries = additionalFields
      .map((field, index) => {
        const startTime = formatTime(field.startTime);
        const endTime = formatTime(field.endTime);

        if (!startTime || !endTime) {
          missingTimeFound = true;
          return null;
        }

        const timeKey = `${startTime.hour}:${startTime.minute}-${endTime.hour}:${endTime.minute}`;

        if (existingTimes.has(timeKey)) {
          duplicateFound = true;
          return null;
        }

        existingTimes.add(timeKey);
        return {
          id: generateId("additional", index),
          startTime,
          endTime,
        };
      })
      .filter(Boolean);
    const mainStartTime = formatTime(dispatchTime);
    const mainEndTime = formatTime(dispatchEndTime);

    if (!mainStartTime || !mainEndTime) {
      missingTimeFound = true;
    } else {
      const mainTimeKey = `${mainStartTime.hour}:${mainStartTime.minute}-${mainEndTime.hour}:${mainEndTime.minute}`;

      if (existingTimes.has(mainTimeKey)) {
        duplicateFound = true;
      } else {
        existingTimes.add(mainTimeKey);
        additionalTimeEntries.push({
          id: generateId("main", 0),
          startTime: mainStartTime,
          endTime: mainEndTime,
        });
      }
    }
    if (missingTimeFound) {
      setErrorMessage("PLEASE SELECT AT LEAST ONE VALID START AND END TIME");
      setOpenErrorModal(true);
      return;
    }
    if (duplicateFound) {
      setErrorMessage("DUPLICATE SCHEDULES DETECTED");
      setOpenErrorModal(true);
      return;
    }

    if (additionalTimeEntries.length === 0) {
      setErrorMessage("PLEASE SELECT AT LEAST ONE VALID BROADCAST TIME SLOT");
      setOpenErrorModal(true);
      return;
    }

    const timeData = {
      id: `main-${Date.now()}`,
      dayOfWeek: location?.state?.day?.weekday,
      off: false,
      startTimeAndEndTimeDTOS: additionalTimeEntries,
    };
    localStorage.setItem('timeData', JSON.stringify(timeData));
    dispatch(setTemplate.setTemplate(timeData));

    const venueId = searchParams.get("venueId") || "defaultVenueId";
    history(`/WeeklyTemplate?venueId=${venueId}`);
  };
  useEffect(() => {
    const currentWeekday = location?.state?.day?.weekday;
    console.log('useEffect fired, Current Weekday:', currentWeekday);
  
    if (currentWeekday && weekTemplateData?.length > 0) {
      const matchedData = weekTemplateData.find(item => item.dayOfWeek === currentWeekday);
      console.log('Matched Data:', matchedData);
  
      if (matchedData) {
        const mainStartTime = matchedData.startTimeAndEndTimeDTOS?.[0]?.startTime;
        const mainEndTime = matchedData.startTimeAndEndTimeDTOS?.[0]?.endTime;
        
        if (mainStartTime && mainEndTime) {
          setDispatchTime(moment(`${mainStartTime.hour}:${mainStartTime.minute}`, "HH:mm"));
          setDispatchEndTime(moment(`${mainEndTime.hour}:${mainEndTime.minute}`, "HH:mm"));
        }
        if (matchedData.startTimeAndEndTimeDTOS?.length > 1) {
          const additionalTimes = matchedData.startTimeAndEndTimeDTOS.slice(1).map(entry => ({
            template: entry.id,
            startTime: moment(`${entry.startTime.hour}:${entry.startTime.minute}`, "HH:mm"),
            endTime: moment(`${entry.endTime.hour}:${entry.endTime.minute}`, "HH:mm"),
          }));
          setAdditionalFields(additionalTimes);
        } else {
          setAdditionalFields([]);
        }
      }
    }
  }, [location?.state?.day?.weekday, weekTemplateData]); 
  useEffect(() => {
    const currentWeekday = location?.state?.day?.weekday;
    if (currentWeekday && weekTemplateData?.length > 0) {
      const matchedData = weekTemplateData.find(
        (item) => item.weekday === currentWeekday
      );
      if (matchedData) {
        setDispatchTime(moment(matchedData.startTime, "hh:mm A"));
        setDispatchEndTime(moment(matchedData.endTime, "hh:mm A"));
      }
    }
  }, [location?.state?.day?.weekday, weekTemplateData]);
  const handleRemoveField = (index) => {
    setAdditionalFields((prevFields) =>
      prevFields.filter((field, i) => i !== index)
    );
  };

  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Header
              menuList={[
                { menuItem: "Logout", handle: () => history("/admin/") },
              ]}
              heading={"WEEKLY SCHEDULE"}
            />
            {handlePopUp && (
              <Dailog
                handleCancel={() => setHandlePopUp(false)}
                errorDailog={popUpMessage}
              />
            )}
            {errorTime && (
              <AlertDialogSlide
                handleCancel={() => setErrorTime(false)}
                errorTime={errorTime}
              />
            )}
            <Box style={classes?.boxWidth}>
              <Typography
                variant="h6"
                align="center"
                sx={{ color: "red", my: 3 }}
              >
                {location?.state?.day?.weekday}
              </Typography>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "8px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel id="template-select-label">
                    Select Daily Template
                  </InputLabel>
                  <Select
                    labelId="template-select-label"
                    value={selectedTemplate}
                    label="Select Daily Template"
                    onChange={handleTemplateChange}
                  >
                    {dailyTemplates
                      .sort((a, b) => {
                        const startTimeA = moment()
                          .hour(
                            a.scheduleTemplateDetailDTOS[0]
                              .startTimeAndEndTimeDTOS[0].startTime.hour
                          )
                          .minute(
                            a.scheduleTemplateDetailDTOS[0]
                              .startTimeAndEndTimeDTOS[0].startTime.minute
                          );
                        const startTimeB = moment()
                          .hour(
                            b.scheduleTemplateDetailDTOS[0]
                              .startTimeAndEndTimeDTOS[0].startTime.hour
                          )
                          .minute(
                            b.scheduleTemplateDetailDTOS[0]
                              .startTimeAndEndTimeDTOS[0].startTime.minute
                          );

                        return startTimeA.isBefore(startTimeB) ? -1 : 1;
                      })
                      .map((template) => (
                        <MenuItem key={template.id} value={template.id}>
                          {template.scheduleTemplateDetailDTOS?.[0]
                            ?.startTimeAndEndTimeDTOS?.[0]?.startTime &&
                            template.scheduleTemplateDetailDTOS?.[0]
                              ?.startTimeAndEndTimeDTOS?.[0]?.endTime
                            ? `${moment()
                              .hour(
                                template.scheduleTemplateDetailDTOS[0]
                                  .startTimeAndEndTimeDTOS[0].startTime.hour
                              )
                              .minute(
                                template.scheduleTemplateDetailDTOS[0]
                                  .startTimeAndEndTimeDTOS[0].startTime.minute
                              )
                              .format("hh:mm A")} - ${moment()
                                .hour(
                                  template.scheduleTemplateDetailDTOS[0]
                                    .startTimeAndEndTimeDTOS[0].endTime.hour
                                )
                                .minute(
                                  template.scheduleTemplateDetailDTOS[0]
                                    .startTimeAndEndTimeDTOS[0].endTime.minute
                                )
                                .format("hh:mm A")}`
                            : "No Time Available"}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <div className="time-box">
                  <TimePicker
                    value={dispatchTime}
                    onChange={(value) => setDispatchTime(value)}
                    showSecond={false}
                    allowEmpty
                    minuteStep={5}
                    placeholder="hh:mm"
                    format={format}
                    use12Hours
                    sx={{ mt: 2, mb: 2 }}
                    className="timeInput"
                    id="broadcastStartTime"
                  />
                  <label htmlFor="broadcastStartTime">
                    Broadcast Start Time
                  </label>
                </div>
                <div className="time-box">
                  <TimePicker
                    value={dispatchEndTime}
                    onChange={(value) => setDispatchEndTime(value)}
                    minuteStep={5}
                    allowEmpty
                    showSecond={false}
                    placeholder="hh:mm"
                    format={format}
                    use12Hours
                    sx={{ mt: 2, mb: 2 }}
                    className="timeInput"
                    id="broadcastEndTime"
                  />
                  <label htmlFor="broadcastEndTime">Broadcast End Time</label>
                </div>
              </div>

              {additionalFields.map((field, index) => (
                <div key={index}>
                  <div
                    style={{
                      border: "1px solid black",
                      borderRadius: "8px",
                      padding: "10px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      // alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {/* <Button
                      className="close-button"
                      variant="contained"
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        backgroundColor: "red",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "red",
                        },
                      }}
                      onClick={() => handleRemoveField(index)}
                    >
                      X
                    </Button> */}
                    <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                      <InputLabel id={`template-select-label-${index}`}>
                        Select Daily Template
                      </InputLabel>
                      <Select
                        labelId={`template-select-label-${index}`}
                        value={field.template}
                        label="Select Daily Template"
                        onChange={(event) =>
                          handleTemplateChangeAdditional(index, event)
                        }
                      >
                        {dailyTemplates.map((template) => {
                          const timePairs =
                            template.scheduleTemplateDetailDTOS[0]
                              ?.startTimeAndEndTimeDTOS || [];

                          if (timePairs.length > 0) {
                            const formattedTime = `${moment()
                              .hour(timePairs[0].startTime.hour)
                              .minute(timePairs[0].startTime.minute)
                              .format("hh:mm A")} - ${moment()
                                .hour(timePairs[0].endTime.hour)
                                .minute(timePairs[0].endTime.minute)
                                .format("hh:mm A")}`;

                            return (
                              <MenuItem key={template.id} value={template.id}>
                                {formattedTime}
                              </MenuItem>
                            );
                          } else {
                            return (
                              <MenuItem key={template.id} value={template.id}>
                                No Time Available
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                    {/* Time pickers for start and end time */}
                    <div className="time-box">
                      <TimePicker
                        value={field.startTime}
                        onChange={(value) =>
                          handleStartTimeChangeAdditional(index, value)
                        }
                        showSecond={false}
                        allowEmpty
                        minuteStep={5}
                        placeholder="hh:mm"
                        format="hh:mm A"
                        use12Hours
                        sx={{ mt: 2, mb: 2 }}
                        className="timeInput"
                        id={`broadcastStartTime-${index}`}
                      />
                      <label htmlFor={`broadcastStartTime-${index}`}>
                        Broadcast Start Time
                      </label>
                    </div>

                    <div className="time-box">
                      <TimePicker
                        value={field.endTime}
                        onChange={(value) =>
                          handleEndTimeChangeAdditional(index, value)
                        }
                        minuteStep={5}
                        allowEmpty
                        showSecond={false}
                        placeholder="hh:mm"
                        format="hh:mm A"
                        use12Hours
                        sx={{ mt: 2, mb: 2 }}
                        className="timeInput"
                        id={`broadcastEndTime-${index}`}
                      />
                      <label htmlFor={`broadcastEndTime-${index}`}>
                        Broadcast End Time
                      </label>
                    </div>
                  </div>
                </div>
              ))}
              <Button
                className="sign-in-button"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                onClick={handleAddMore}
              >
                 ADD BROADCASTS
              </Button>
              <Button
                className="sign-in-button"
                fullWidth
                variant="contained"
                sx={{ mt: 0, mb: 4 }}
                onClick={handleSubmit}
              >
                SAVE
              </Button>
            </Box>
          </Box>
        </div>
      </Container>
      <Dialog
        open={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "20px",
            width: "500px",
            textAlign: "center",
          },
        }}
      >
        <DialogContent>
          <Typography variant="h4">
            <b>{errorMessage}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenErrorModal(false)}
            color="primary"
            autoFocus
            sx={{
              backgroundColor: "red",
              color: "white",
              width: "100%",
              padding: "10px",
              "&:hover": {
                backgroundColor: "red",
              },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WeeklySet;
